// import React, { useEffect, useRef, useState } from 'react'
// import Contactbanner from '../container/contactBanner/Contactbanner'
// import Contactsection1 from '../container/contactSection1/Contactsection1'
// import Contactsection2 from '../container/contactSection2/Contactsection2'
// import { useLocation } from 'react-router-dom'

// const ContactUs = () => {
//   const location = useLocation()
//   const sectionRef = useRef(null);
//   const [scrollToSection, setScrollToSection] = useState(false);

//   useEffect(() => {
//     // Set scrollToSection to true after component mounts
//     setScrollToSection(true);
//   }, []);

//   useEffect(() => {
//     // Scroll to the section when scrollToSection becomes true
//     if (location.state.scroll == 'true' && scrollToSection && sectionRef.current) {
//       sectionRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [scrollToSection]);


//   return (
//     <div>
//         <Contactbanner/>
//         <div  ref={sectionRef} style={{transform:'translateY(-20px)'}}></div>
//         <Contactsection2 selectedtab={location.state.scroll}/>
//         <Contactsection1/>
//     </div>
//   )
// }

// export default ContactUs


// ContactUs.jsx
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Contactbanner from '../container/contactBanner/Contactbanner';
import Contactsection1 from '../container/contactSection1/Contactsection1';
import Contactsection2 from '../container/contactSection2/Contactsection2';
import { useGlobalState } from '../GlobalStateContext';

const ContactUs = () => {
  const location = useLocation();
  const sectionRef = useRef(null);
  const [scrollToSection, setScrollToSection] = useState(false);

  const { scrollPosition } = useGlobalState();

  // Example of accessing and modifying global state
  
  useEffect(() => {
    // Set scrollToSection to true after component mounts
    setScrollToSection(true);

    console.log('scrollPosition',scrollPosition);
  }, []);

  useEffect(() => {
    // Scroll to the section when scrollToSection becomes true
    if (scrollPosition == 1 && scrollToSection && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollPosition, scrollToSection]);
  

  return (
    <div>
      <Contactbanner/>
      
      <div ref={sectionRef}></div>
      <Contactsection2 scrollPosition={scrollPosition}/>
      <Contactsection1/>
    </div>
  );
}

export default ContactUs;
