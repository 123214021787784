import React, { useEffect } from 'react'
import Testimonialbanner from '../container/testimonialBanner/Testimonialbanner'
import Homereview from '../container/homeReview/Homereview'
import Testimonialreview from '../container/testimonialReview/Testimonialreview'
import { useLocation } from 'react-router-dom'

const TestimonialDetail = () => {
    const location = useLocation();
  const cardDetails = location.state?.cardDetails || {};
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
      }, []);
  return (
    <div>
        <Testimonialbanner/>
        <Homereview/>
        <div id="testimonialReview">
            <Testimonialreview cardDetails={cardDetails}/>
        </div>
    </div>
  )
}

export default TestimonialDetail