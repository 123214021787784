import React, { useEffect } from "react";
import { BlogHero } from "../container";
import Homeblog from "../container/HomeBlog/Homeblog";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
    // Add a class to the body to prevent transition effect
    document.body.classList.add("transition-page");

    // Cleanup: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove("transition-page");
    };
  }, []);
  return (
    <>
      <BlogHero />
      <Homeblog />
      {/* <LatestBlog /> */}
    </>
  );
};

export default Blogs;
