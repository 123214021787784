import React from "react";
import "./AboutWeAre.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const AboutWeAre = () => {
  const{t,i18n} = useTranslation();
  const imageSrc = i18n.language === "en"
  ? require("../../assets/logos/about_whatwe_logo.png")
  : require("../../assets/logos/about_whatwe_logo_hu.png");
  return (
    <div className="main_wrapp">
      <div className="container aboutweare_main_wrapp">
        <div className="aboutweare_inner_wrapp">
          <div className="aboutweare_img_wrapp_new">
            <img
              src={images.about_whatwe2}
              className="about_whatwe_img1_new"
              alt="about_whatwe_img1"
              loading="lazy" decoding='async'
            />
            <img
               src={imageSrc}
              className="about_whatwe_rotate_logo"
              alt="about_whatwe_logo"
            />
          </div>
          <div className="aboutwhatwe_textwrapp">
            <p className="aboutwhatwe_orange_text">{t('WHO WE ARE')}</p>
            <p className="aboutwhatwe_blue_text">
              {t("Creating a community of life long learners")}
            </p>
            <div className="aboutwhatwe_blueline_text_wrapp">
              <div className="aboutwhatwe_blueline"></div>
              <p className="aboutwhatwe_blueline_text">
                {t("USAIntern.club is your full service agency,")}
              </p>
            </div>
            <p className="aboutwhatwe_blueline_text_blw">#{t("CareerDevelopment")}</p>
            <p className="aboutwhatwe_blueline_text_blw">#{t("AdventureAwaits")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWeAre;
