import React, { useEffect } from 'react'
import TaxreturnBanner from '../container/taxreturnbanner/TaxreturnBanner'
import TaxreturnDetails from '../container/taxreturndetails/TaxreturnDetails'

const TaxReturn = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
          });
      }, []);
  return (
    <div>
        <TaxreturnBanner/>
        <TaxreturnDetails/>
    </div>
  )
}

export default TaxReturn