import React from "react";
import "./AboutHowCanHelp.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutHowCanHelp = () => {
  const{t} = useTranslation();
  return (
    <div className="main_wrapp">
      <div className="container ahch_main_wrapp">
        <div className="how_can_help_images_side_wrapp">
          <img
            src={images.howcanhelpimg1}
            className="how_can_help_first_img"
            alt="how-can-i-help"
            loading="lazy" decoding='async'
          />
          <div className="how_can_help_secound_img">
            <img src={images.howcanhelpimg2} alt="how_can_help_secound_img" 
            loading="lazy" decoding='async'/>
          </div>
        </div>
        <div className="how_can_help_text_side_wrapp">
          <div className="how_can_help_text_side_part1">
            <p className="aboutwhatwe_orange_text">{t("HOW CAN WE HELP YOU?")}</p>
            <p className="aboutwhatwe_blue_text">
              {t("Creating a community of international explorers")}
            </p>
            <p className="about_whychoose_des_text">
              {t("Do you want to get an awesome internship abroad? Are you not quite sure which program is the right choice for you? Do you have no idea how to find an internship remotely? Do you want to make sure that you will be legally participating in a training program in the USA? Are you ready to have an experience of a lifetime, you just don’t know where to start?")}
            </p>
          </div>
          <div className="how_can_help_text_side_part2">
            <div className="how_can_help_text_side_part2_first">
              <div className="how_can_help_text_side_part2_first_1">
                <img src={images.howcanhelplogo1} alt="howcanhelplogo1" loading="lazy" decoding='async'/>
                <p>{t("GOOD NEWS!! WE ARE HERE FOR YOU, EVERY STEP OF THE WAY!")}</p>
              </div>
              <p>
                {t("We know, that even if you are very excited about an internship abroad, at the same time it can be very stressful and time consuming to find out all the details you need to know to apply and get a visa in the USA … just...")}
              </p>
            </div>
            <div className="how_can_help_text_side_part2_first">
              <div className="how_can_help_text_side_part2_first_1">
                <img src={images.howcanhelplogo2} alt="howcanhelplogo2" loading="lazy" decoding='async'/>
                <p>{t("RELAX …")}</p>
              </div>
              <p>
                {t("…we are here to give you professional advice, give you all the information you are seeking and help you get an internship/professional career training in the USA which will definitely jump start or advance your career. Want to find out everything about our programs?")}
              </p>
            </div>
            <div className="about_book_btn_div">
            <Link to={"/qualify"} className="about_book_btn">{t("Book Your Free Consultation")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHowCanHelp;
