import React from "react";
import "./AboutHero.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const AboutHero = () => {
  const{t} = useTranslation();
  return (
    <div className="about_hero_main_wrapp">
      <img src={images.about_banner} alt=""  className="about-banner-img"  loading="lazy" decoding='async'/>
      <div className="about_hero_text_section_wrapp">
        <div className="about_hero_text_section_inner_wrapp container">
          <div className="about_hero_head_div">
            <div className="green_vr"></div>
            <p className="large_shadow_text_white">{t("About Us")}</p>
          </div>
          <p className="des_shadow_text_white" style={{ maxWidth: "360px" }}>
            {t("Connect and explore opportunities")}<br /> {t("with us")}
          </p>
        </div>
      </div>
      <img src={images.herowave} alt="about-wave" className="about_wave_img1" />
      <div className="about_wave_img"></div>
    </div>
  );
};

export default AboutHero;
