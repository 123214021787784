import React from "react";
import "./RecentBlogCard.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";

const RecentBlogCard = ({ img, title, des, screen }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Link to={screen} className="recentBlogCard_main_wrapp" onClick={scrollToTop}>
      <div className="recentBlogCard_img_wrapp">
        <img src={img} alt="" className="recentBlogCard_img" />
        <img
          src={images.blog_card_shap}
          alt=""
          className="recentBlogCard_shap"
        />
        <img
          src={images.blog_card_arrow}
          alt=""
          className="recentBlogCard_arrow"
        />
      </div>
      <div className="recentBlogCard_text_wrapp">
        <h4>{title}</h4>
        <p>{des}</p>
      </div>
    </Link>
  );
};

export default RecentBlogCard;
