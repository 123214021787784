import React from 'react'
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
const CookieConsentNew = () => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Reject"
        declineButtonStyle={{
          fontSize: "14px",
          fontFamily:"var(--font-rubik)",
          borderRadius:"20px",
          width:"84px",
          height:'38px',
          background:"none",
          color:"black"

        }}
        cookieName="USA-Intern-Cookie"
        style={{ 
          background: "white" ,
          color:"black",
          fontFamily:"var(--font-base)",
        }}

        buttonStyle={{ 
        color: "white",
        background:"black",
        fontSize: "14px",
        fontFamily:"var(--font-rubik)",
        borderRadius:"20px",
        width:"84px",
        height:'38px'
      }}
        expires={150}
        enableDeclineButton
      >
        We and selected third parties use cookies or similar technologies for technical purposes, to enhance site navigation, analyze site usage, assist in our marketing efforts, and for other purposes as specified in the <span ><Link to={'/CookiePolicy'}  style={{color:"#3C3CF2"}}>Cookie Policy</Link>  </span>{" "}
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </div>
  )
}

export default CookieConsentNew