import React, { useState } from 'react'
import "./homequestions.css"
import images from '../../constants/images'
import { useTranslation } from 'react-i18next';

const HomeQuestions = () => {
    const{t} = useTranslation();
    // const [isOpen, setIsOpen] = useState(false);
    // const toggleAccordion = () => {
    //     setIsOpen(!isOpen);
    //   };
    const accordionData = [
        {
          title: 'What is a J-1 visa?',
          content: "The J1 visa is issued to individuals who participate in various types of student or internship exchange programs in the USA. More information in English:",
          link: "http://j1visa.state.gov/"
        },
        {
          title: 'When can I arrive in the U.S. with my J1 visa? How long can I remain after my program ends?',
          content: 'You can enter the USA 30 days before the date indicated on the DS2019 form. After the official expiration of the exchange program, you can stay in the USA for a maximum of 30 days.',
        },
        {
            title: 'Can I continue my internship/career training in the 30 days after the end of the program?',
            content: 'No. The date for the end of the official program on DS2019 must be observed and no practical activities can be carried out during these 30 days. After the end of the program, the participant must return home or use these 30 days exclusively for travel. If the participant plans to travel for more than 30 days, a change of status to a B2 tourist visa is required (the ESTA does not automatically become valid, as it was not the one the participant originally entered with).',
        },
        {
            title: 'Can I take a job with another company while on my J1 visa?',
            content: 'It is strictly forbidden to engage in activities that are not included in the DS2019 form, so you cannot work for another company (e.g. weekend work, etc.).',
        },
        {
            title: 'What is SEVIS?',
            content: 'SEVIS stands for (Student and Exchange Visitor Information System) and is an online system where the US Department of Homeland Security (DHS) stores information about J1 visa participants.',
        },
        {
            title: 'What is Form DS-2019 (Certificate of Eligibility)?',
            content: 'The DS2019 is issued by the visa sponsor and is a necessary document to obtain a J1 visa. It contains the details of the program and proves that the participant meets the requirements of the program.',
        },
        {
            title: 'What happens to my J1 visa if I cancel the exchange program?',
            content: 'If the participant discontinues the program, they must notify the visa sponsor so that this information can be changed in SEVIS. The J1 visa becomes invalid, so you must leave the USA immediately. The participant will therefore not receive the 30 days intended for travel after the end of the program, as he has not completed the program.',
        },
        {
            title: 'What is the J-2 Visa?',
            content: 'A J2 visa is issued by the U.S. Embassy to the spouse or unmarried child under the age of 21 of a J1 participant to travel to the U.S. with or join the participant later. More information in English:',
            link:"http://j1visa.state.gov/basics/j2-visa/",
        },
        {
            title: 'Can I participate in another J1 program after finishing my current program?',
            content: "The participant's current J1 visa is valid for the current program only, with the current program sponsor. After the end of the program, you have to return home from the USA. If the participant wants to participate in another program of the J1 visa, with a different program sponsor, he must apply for a new visa from home.",
        },

        // Add more accordion data as needed
      ];
   
    
  
      const [openAccordion, setOpenAccordion] = useState(Array(accordionData.length).fill(false));

  
      const toggleAccordion = (index) => {
          const updatedAccordions = openAccordion.map((isOpen, i) => i === index ? !isOpen : false);
          setOpenAccordion(updatedAccordions);
      };

  
      return (
          <div className='home-question'>
              <div className="home-question-wrap">
                  <div className='home-question-div'>
                      <div className="home-question-sec1">
                          <div className='contents'>
                              <div className='head'>
                                  <p className='title'>{t("Do You Have Any Questions")}</p>
                                  <img src={images.question_img} alt="question-img" className='question-img'/>
                              </div>
                              <p className='des'>{t("We have answers (well, most of the times!) Below you'll find answers to the most common questions you may have on USAintern.")} </p>
                          </div>
                      </div>
                      <div className="home-question-sec2">
                          <div>
                              <div>
                                  {accordionData.map((accordion, index) => (
                                      <div className='accordian-main' key={index}>
                                          <div className='title-btn-div'>
                                              <div className='title'>
                                                  <p>{t(accordion.title)}</p>
                                              </div>
                                              <div className='btn'>
                                                  <button onClick={() => toggleAccordion(index)}>
                                                      <img src={openAccordion[index] ? images.question_red_btn : images.question_blue_btn} alt="accordian-open" />
                                                      {/* Add your open/close icons */}
                                                  </button>
                                              </div>
                                          </div>
                                          <div className={`accordian-del ${openAccordion[index] ? 'open' : 'closed'}`}>
                                          {index === 0 || index === 7 ? ( // Check if it's the first accordion item
                                            <p className='accordian-del-p'>
                                                {t(accordion.content)} <a href={accordion.link} target="_blank"  className='blue-link-acc'>{accordion.link}</a>
                                            </p>
                                            ) : (
                                            <p className='accordian-del-p'>{t(accordion.content)}</p>
                                            )}
                                          </div>
                                      </div>
                                  ))}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
  };
  
  export default HomeQuestions;