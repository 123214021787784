import React, { useEffect } from "react";
import { RecentBlogCard } from "../components";
import images from "../constants/images";
import BlogDetailPart2 from "../container/blogDetailPart1/BlogDetailPart2";
import BlogDetailPart3 from "../container/blogDetailPart1/BlogDetailPart3";
import BlogDetailPart4 from "../container/blogDetailPart1/BlogDetailPart4";

const data = [
    
  {
    id: 2,
    title: "Land Your Dream Internship: Craft an Outstanding U.S. Application",
    des: "The U.S. internship scene is competitive, but with the right tools, you can make your application stand out.",
    navigate: "/Land_Your_Dream_Internship:_Craft_an_Outstanding_U.S._Application",
    img: images.blog_img1,
  },
  {
    id: 3,
    title:
      "Conquering the Interview: Essential Tips for Nailing Your U.S. Internship",
    des: "Landing a U.S. internship interview is a fantastic achievement. Now comes the next hurdle: acing the interview itself.",
    navigate: "/Conquering_the_Interview:_Essential_Tips_for_Nailing_Your_U.S._Internship",
    img: images.blog_img3,
  },
  {
    id: 4,
    title:
      "Navigating the J-1 Visa Process Made Simple with Usa Intern Club",
    des: "Embarking on a cultural exchange program in the U.S. through the J-1 visa can be an exciting journey.",
    navigate: "/Navigating_the_J1_Visa_Process_Made_Simple_with_USAINTERN.CLUB",
    img: images.blog_img2,
  },
  {
    id: 1,
    title:
      "Navigating J-1 Visa Taxes: A Complete Guide To Filing Your U.S. Tax Return",
    des: " You will require a specialized tax return form rather than a standard one.Rely on our trusted partner TaxReturn4You.",
    navigate: "/Navigating_J1-Visa_Taxes:_A_Complete_Guide_To_Filing_Your_U.S._Tax_Return",
    img: images.taxbanner_blog,
  },
];

const BlogDetail = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);

  return (
    <div>
      <BlogDetailPart2 />

      {/* recent blog section start */}

      <div className="container recent_blog_main_wrapp">
        <h2>Recent Blog</h2>
        <div className="recent_blog_card_wrapp">
          {/* <RecentBlogCard img={images.recent_blog_img3} /> */}
          {/* <RecentBlogCard img={images.recent_blog_img1} /> */}

          {data.map((item, index) => {
            return (
              <RecentBlogCard
                title={item.title}
                img={item.img}
                des={item.des}
                key={item.id}
                screen={item.navigate}
              />
            );
          })}
        </div>
      </div>

      {/* recent blog section end */}
    </div>
  );
};

export default BlogDetail;
