import React, { useEffect, useState } from "react";
import "./CustomDropdown.css"; // Add your own CSS file for styling
import images from "../../constants/images";
import { FaCaretDown } from "react-icons/fa";
import i18n from 'i18next';

const countries = [
  { name: "En", logo: images.us_flag ,lang: "en"},
  { name: "Hu", logo: images.hug_flag ,lang: "hu"},

];

const CustomDropdown = () => {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    console.log("selectedCountry.logo", selectedCountry.logo);
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
    // Change the language using i18next
    i18n.changeLanguage(country.lang);
  };

  return (
    <div className="custom-dropdown">
      <div
        className={`dropdown-header ${dropdownOpen ? "open" : ""}`}
        onClick={handleDropdownToggle}
      >
        {selectedCountry ? (
          <div className="selected-country">
            {/* <span className="country-logo">{selectedCountry.logo}</span> */}
            <img src={selectedCountry.logo} alt="" className="country-logo" />
            <span className="country-name">{selectedCountry.name}</span>
            <FaCaretDown size={20} color={"var(--color-blue)"} />
          </div>
        ) : (
          <span>Select Country</span>
        )}
      </div>
      {dropdownOpen && (
        <div className="dropdown-options">
          {countries.map((country, index) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleCountrySelect(country)}
            >
              {/* <span className="country-logo">{country.logo}</span> */}
              <img src={country.logo} alt="" className="country-logo" />
              <span className="country-name1">{country.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
