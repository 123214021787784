import React from 'react'
import "./homeabout.css"
import images from '../../constants/images'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HomeAbout = () => {
    const{t} = useTranslation();
  return (
    <div className='home-about'>
        <div className='home-about-wrap'>
            <div className="home-about-content">
                <div className="home-about-head">
                    <p className='h-a-title1'>{t('ABOUT US')}</p>
                    <p className='h-a-title2'>{t('Who we are')}</p>
                    <p className='h-a-des'> {t("USAIntern.club is your full service agency, guiding you through every stage – from discovering your ideal internship in the USA to navigating the entire J1 visa process and ensuring you're fully prepared for an incredible year ahead. Your only concern? Deciding what to pack for the adventure of a lifetime!")}</p>
                </div>
                <div className="home-about-details">
                    <div className='rectangle'> 
                        <p className='p1'>10</p>
                        <p className='p2'>{t('Years of')}</p>
                        <p className='p2'> {t('Expertise')}</p>
                    </div>
                    <div className='description'>
                        <div className='h-a-mission'>
                            <p className='des-p1'><img src={images.tick} alt="tick-mark" /> {t("Mission")}</p>
                            <p className='des-p2'>{t("Empowering educated young individuals with the chance to acquire invaluable professional and cultural experiences in the highly competitive environment of the United States of America.")}</p>
                        </div>
                        <div className='h-a-vision'>
                            <p className='des-p1'><img src={images.tick} alt="tick-mark" /> {t("Vision")}</p>
                            <p className='des-p2'>{t("Our vision is to be the number one choice for Hungarian students and young professionals for finding an internship or training in the USA by providing an excellent service with reliable information and advice regarding all aspects of the programs")}</p>
                        </div>
                    </div>
                </div>
                <div className="h-a-social-btn">
                    <a href="mailto:info@usaintern.club" className='h-a-email'>
                        <img src={images.h_a_email} alt="email" />
                        <div className='h-a-del'>
                            <p>{t("Email us")}</p>
                            <a href="mailto:info@usaintern.club">info@usaintern.club</a>
                        </div>
                    </a>
                    <Link to={"skype:usaintern.club?chat"} className='h-a-skype'>
                        <img src={images.h_a_skype} alt="skype" />
                        <div className='h-a-del'>
                            <p>Skype</p>
                            <Link to={"skype:usaintern.club?chat"}
                            >usaintern.club</Link>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="home-about-img">
                <img src={images.h_a_img} alt="about-img" loading="lazy" decoding='async'/>
            </div>
        </div>
    </div>
  )
}

export default HomeAbout