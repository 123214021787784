import React from "react";
import "./BlogDetailPart1.css";
import images from "../../constants/images";

const BlogDetailPart5 = () => {
  return (
    <div className="main_wrapp">
      <div>
        <div className="bd_img_wrapp">
          <img src={images.blog_img1_banner} alt="blog_detail_img" className="blog_detail_large_img"/>
          <img src={images.blog_img1_mob} alt="blog_detail_img_mob" className="blog_detail_mob_img_au" />
        </div>
        <div className="bd_first_blue_wrapp">
          <span>March 4, 2024</span>
          <h1>Land Your Dream Internship: Craft an Outstanding U.S. Application</h1>
        </div>
        <div className="bd_details_main_wrapp">
          <p className="bd_details_first_paregraph">
          The U.S. internship scene is competitive, but with the right tools, you can make your application stand out. This blog dives into creating an impactful resume and cover letter to impress potential employers and secure your dream internship.

          </p>
          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
          Resume: Your Skills on Display
          </h2>
          <h3 className="bd_details_secound_title">Craft a Compelling Narrative:</h3>
          <p className="bd_details_secound_paregraph">
          Instead of a generic resume, showcase your talents and experiences through a story that highlights your passion for internships and your potential to contribute to the success of your future host company.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Focus on Achievements:</h3>
          <p className="bd_details_secound_paregraph">
          Quantify your accomplishments wherever possible. Use numbers and metrics to showcase the impact you made in previous roles. For example, instead of simply saying "managed social media," state "increased website traffic by 20% through targeted social media campaigns."
          <br />
          </p>
          <h3 className="bd_details_secound_title">Keywords Are Key:</h3>
          <p className="bd_details_secound_paregraph">
          Use relevant keywords from the job description throughout your resume. This helps applicants tracking systems (ATS) recognize your qualifications and make your resume stand out.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Readability is King:</h3>
          <p className="bd_details_secound_paregraph">
          Keep your resume concise and easy to read. Use clear headings, bullet points, and action verbs.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Proofread, Proofread, Proofread:</h3>
          <p className="bd_details_secound_paregraph">
          Typos and grammatical errors create a negative impression. Proofread your resume meticulously before submitting it.
          <br />
          </p>
          <br />
          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
          Cover Letter: Your Personal Introduction
          </h2>
          <h3 className="bd_details_secound_title">Show Your Enthusiasm:</h3>
          <p className="bd_details_secound_paregraph">
          Express genuine interest in the internship program and the specific fields you are targeting.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Connect the Dots:</h3>
          <p className="bd_details_secound_paregraph">
          Bridge the gap between your resume and the internship program. Explain how your skills and experiences make you a perfect fit for the program.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Call to Action:</h3>
          <p className="bd_details_secound_paregraph">
          End your cover letter with a clear call to action. Express your eagerness to learn more and be interviewed.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Keep it Concise: </h3>
          <p className="bd_details_secound_paregraph">
          Your cover letter should be one page long. Focus on the most impactful information and get straight to the point.

          <br />
          </p>
            <br />
          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
           Additional Tips:
          </h2>
          <h3 className="bd_details_secound_title">Format Matters:</h3>
          <p className="bd_details_secound_paregraph">
          Use a professional resume template and maintain a consistent format throughout. Usa Intern Club offers a variety of templates to get you started!
          <br />
          </p>
          <h3 className="bd_details_secound_title">Quantify Your Skills:</h3>
          <p className="bd_details_secound_paregraph">
          Back up your skills with measurable achievements.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Proofread Again:</h3>
          <p className="bd_details_secound_paregraph">It's essential to have another person review your resume and cover letter for any errors. Back up your skills with measurable achievements.
          <br />
          </p>
          <br />

          <h2
            className="aboutwhatwe_blue_text1"
            style={{ width: "100%", margin: "1rem auto" }}
          >
           Extra Points:
          </h2>
          <h3 className="bd_details_secound_title">Stand Out with Design: </h3>
          <p className="bd_details_secound_paregraph">
          Consider a visually appealing resume template, but ensure readability remains primary.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Link to a Portfolio:</h3>
          <p className="bd_details_secound_paregraph">
          Include a link to your online portfolio, website showcasing past projects, or your LinkedIn profile.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Networking:</h3>
          <p className="bd_details_secound_paregraph">
          Building connections within the industry can lead to valuable internship opportunities.
          <br />
          </p>
          <h3 className="bd_details_secound_title">Research Industry Trends:</h3>
          <p className="bd_details_secound_paregraph  pbb1">
          Stay informed about current trends in your field of interest.
          <br />
          </p>
          {/* <br />
          <br /> */}
          <p className="bd_bold_question">By following these tips and showcasing your unique talents, you can create a compelling application that will grab the attention of U.S. internship recruiters. Good luck!</p>
        </div>
        <div className="bd_secound_blue_box_wrapp">
          <div className="bd_secound_blue_first_part">
            <img src={images.navlogo} style={{ objectFit: "contain" }} alt="navlogo" />
            <h3>Team Usa Intern Club</h3>
            {/* <a href="">View Posts</a> */}
          </div>
          <div className="bd_secound_blue_secound_part" />
          <div className="bd_secound_blue_third_part">
            <p>Post Tags:</p>
            <div>
              <a href="#">J-1 VISA</a>
              <a href="#">VISA PROCESS</a>
              <a href="#">VISA ASSISTANCE</a>
              <a href="#">INTERNATIONAL STUDENTS</a>
              <a href="#">WORK AND TRAVEL PROGRAM</a>
              <a href="#">STUDENT EXCHANGE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPart5;
