import React, { useEffect, useRef } from 'react'
import "./privacypolicy.css"
// import { Link } from 'react-router-dom'
import images from '../../constants/images'
import { HashLink as Link } from 'react-router-hash-link'

const Privacypolicy = () => {
    const personal_info = [
        { id:1, field: "Names" },
        { id:2,field: "Mailing addresses" },
        { id:3,field: "Passwords" },
        { id:4,field: "Phone numbers" },
        { id:5,field: "Job titles" },
        { id:6,field: "Contact preferences" },
        { id:7,field: "Email addresses" },
        { id:8,field: "Usernames" },
        { id:9,field: "Billing addresses" },
      ];
      const tabledata = [
        { 
            column1: 'A. Identifiers',
            column2: 'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier,  online identifier, Internet Protocol address, email address, and account name', 
            column3: 'YES' 
        },
        { 
            column1: 'B. Personal information as defined in the California Customer Records statute',
            column2: 'Name, contact information, education, employment, employment history, and financial information', 
            column3: 'YES' 
        },
        { 
            column1: 'C. Protected classification characteristics under state or federal law',
            column2: 'Gender and date of birth', 
            column3: 'YES' 
        },
        { 
            column1: 'D. Commercial information',
            column2: 'Transaction information, purchase history, financial details, and payment information', 
            column3: 'YES' 
        },
        { 
            column1: 'E. Biometric information',
            column2: 'Fingerprints and voiceprints', 
            column3: 'NO' 
        },
        { 
            column1: 'F. Internet or other similar network activity',
            column2: 'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications,  systems, and advertisements', 
            column3: 'NO' 
        },
        { 
            column1: 'G. Geolocation data',
            column2: ' Device location', 
            column3: 'NO' 
        },
        { 
            column1: 'H. Audio, electronic, visual, thermal, olfactory, or similar information',
            column2: ' Images and audio, video or call recordings created in connection with our business activities', 
            column3: 'NO' 
        },
        { 
            column1: 'I. Professional or employment-related information',
            column2: ' Business contact details in order to provide you our Services at a business level or job title, work history, and professional  qualifications if you apply for a job with us', 
            column3: 'YES' 
        },
        { 
            column1: 'J. Education Information',
            column2: ' Student records and directory information', 
            column3: 'YES' 
        },
        { 
            column1: 'K. Inferences drawn from collected personal information',
            column2: ' Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for  example, an individual’s preferences and characteristics', 
            column3: 'YES' 
        },
        { 
            column1: 'L. Sensitive personal Information',
            column2: "Account login information, citizenship or immigration status, passport numbers, racial or ethnic origin, state id card  numbers and drivers &apos; licenses", 
            column3: 'YES' 
        },
      ];

      const rights = [
        { id:1, field: "Category A - As long as the user has an account with us" },
        { id:2,field: "Category I - As long as the user has an account with us" },
        { id:3,field: "Category B - As long as the user has an account with us" },
        { id:4,field: "Category J - As long as the user has an account with us" },
        { id:5,field: "Category C - As long as the user has an account with us" },
        { id:6,field: "Category K - As long as the user has an account with us" },
        { id:7,field: "Category D - As long as the user has an account with us" },
        { id:8,field: "Category L - As long as the user has an account with us" },
      ];
      const personal_info2 = [
        { id:1, field: "Targeting cookies/Marketing cookies" },
        { id:2,field: "Beacons/Pixels/Tags" },
        { id:3,field: "Social media cookies" },
        { id:4,field: " Click redirects: Tax Services" },
      ];
    //   const handlepersonal = ()=>{
    //     const handlepersonalsection = document.getElementById("PP_summery");
    //     if (handlepersonalsection) {
    //         const targetposition = handlepersonalsection.offsetTop - 84.2;
    //         window.scrollTo({ top: targetposition, behavior: 'smooth'})
            
    //     }

    //   }
    const tableOfContentRef = useRef(null);

//   const handleClick = () => {
//     if (tableOfContentRef.current) {
//       const navbarHeight = document.querySelector('.nav_main_wrapp').offsetHeight;
//       const targetPosition = tableOfContentRef.current.offsetTop - navbarHeight;
//       window.scrollTo({ top: targetPosition, behavior: 'smooth' });
//     }
//   };
  const handleClick = (el) => {
    // Calculate the target position with an 85px offset
    const offset = 85;
    const targetPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
    
    // Scroll to the target position with smooth behavior
    window.scrollTo({ top: targetPosition, behavior: 'instant' });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div className='PP_main_div'>
        <div className='PP_title_div'>
            <h2>PRIVACY POLICY</h2>
        </div>
        <div className='PP_content_div'>
            <p>This privacy notice for Usa Intern Club (Veres Gyöngyi, EV.) (doing business as Direct Project Management) ("<b>we</b>," "<b>us</b>," or "<b>our</b>"), describes how and why we might collect, store, use, and/or share ("<b>process</b>") your information when you use  our services ("<b>Services</b>"), such as when you:</p>
            <div className='PP_list1'>
                <React.Fragment>
                    <li>
                    Visit our website at <a href="http://www.usaintern.club" className='TC_link' target='_blank'>http://www.usaintern.club</a>, or any website of ours that links to this privacy notice
                    </li>
                    <li> Engage with us in other related ways, including any sales, marketing, or events</li>
                </React.Fragment>
            </div>
            <p><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns,  please contact us at <a href="mailto:info@usaintern.club" target="_blank">info@usaintern.club.</a> </p>
            <br />
            <div className='PP_summery'>
                <h2 className='PP_sub_title'>SUMMARY OF KEY POINTS</h2>
                <br />
                <hr className='PP_head-bottom'/>
                <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <Link 
                to={"/PrivacyPolicy#tableofcontent"}
                scroll={(el) => handleClick(el)}
                 className='TC_link'>table of contents</Link>  below to find the section  you are looking for.</p>
                <br />
                <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and  features you use. Learn more about <Link to={"/PrivacyPolicy#Personal-information-you-disclose-to-us"}
                 scroll={(el) => handleClick(el)}
                  className='TC_link'> personal information you disclose to us.</Link></p>
                <br />
                <p><b>Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about 
                <Link to={"/PrivacyPolicy#Sensitive-Information"}
                 scroll={(el) => handleClick(el)}> sensitive information we process.</Link></p>
                <br />
                <p><b>Do we receive any information from third parties?</b> We do not receive any information from third parties.</p>
                <br />
                <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your  information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <Link to={"/PrivacyPolicy#HOW-DO-WE-PROCESS-YOUR-INFORMATION?"}
                 scroll={(el) => handleClick(el)}>how we process your information.</Link></p>
                <br />
                <p><b>In what situations and with which types of parties do we share personal information?</b> We may share information in specific situations and with specific categories of third parties. Learn more about <Link to={"/PrivacyPolicy#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?"}
                scroll={(el) => handleClick(el)}> when and with whom we share your  personal information.</Link></p>
                <br />
                <p><b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage  technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify  your information. Learn more about  <Link
                to={"/PrivacyPolicy#HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE?"}
                scroll={(el) => handleClick(el)}>how we keep your information safe.</Link></p>
                <br />
                <p><b>What are your rights? </b>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <Link
                to={"/PrivacyPolicy#WHAT-ARE-YOUR-PRIVACY-RIGHTS?"}
                scroll={(el) => handleClick(el)}> your privacy rights.</Link></p>
                <br />
                <p ><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by visiting <a href="http://www.usaintern.club"  target='_blank' className='TC_link'>http://www.usaintern.club</a>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <br />
                <p>Want to learn more about what we do with any information we collect? <Link
                to={"/PrivacyPolicy#tableofcontent"}
                scroll={(el) => handleClick(el)}> Review the privacy notice in full.</Link></p>
            </div>
            <br />

            <div className='PP_summery' id='tableofcontent'>
                <h2 className='PP_sub_title'>TABLE OF CONTENTS</h2>
                <br />
                <hr className='PP_head-bottom'/>
                <div className='PP_table_Contents'>
                    <React.Fragment>
                        <li >
                            <p>1.</p>
                            <Link 
                            to={"/PrivacyPolicy#WHAT-INFORMATION-DO-WE-COLLECT?"} 
                            scroll={(el) => handleClick(el)}
                            >WHAT INFORMATION DO WE COLLECT?</Link>
                        </li>
                        <li >
                            <p>2.</p>
                            <Link
                            to={"/PrivacyPolicy#HOW-DO-WE-PROCESS-YOUR-INFORMATION?"}
                            scroll={(el) => handleClick(el)}>
                            HOW DO WE PROCESS YOUR INFORMATION?</Link>
                        </li>
                        <li >
                            <p>3.</p>
                            <Link
                            to={"/PrivacyPolicy#WHAT-LEGAL-BASES-DO-WE-RELY-ON-TO-PROCESS-YOUR-INFORMATION?"} 
                            scroll={(el) => handleClick(el)}>
                            WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</Link>
                        </li>
                        <li >
                            <p>4.</p>
                            <Link
                            to={"/PrivacyPolicy#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?"} 
                            scroll={(el) => handleClick(el)}
                            >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Link>
                        </li>
                        <li >
                            <p>5.</p>
                            <Link
                            to={"/PrivacyPolicy#DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES?"} 
                            scroll={(el) => handleClick(el)}
                            >DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Link>
                        </li>
                        <li >
                            <p>6.</p>
                            <Link
                            to={"/PrivacyPolicy#HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS?"} 
                            scroll={(el) => handleClick(el)}
                            >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Link>
                        </li>
                        <li >
                            <p>7.</p>
                            <Link
                            to={"/PrivacyPolicy#IS-YOUR-INFORMATION-TRANSFERRED-INTERNATIONALLY?"} 
                            scroll={(el) => handleClick(el)}
                            >IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</Link>
                        </li>
                        <li >
                            <p>8.</p>
                            <Link to={"/PrivacyPolicy#HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION?"} 
                            scroll={(el) => handleClick(el)}
                            >HOW LONG DO WE KEEP YOUR INFORMATION?</Link>
                        </li>
                        <li >
                            <p>9.</p>
                            <Link to={"/PrivacyPolicy#HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE?"} 
                            scroll={(el) => handleClick(el)}
                            >HOW DO WE KEEP YOUR INFORMATION SAFE?</Link>
                        </li>
                        <li >
                            <p>10.</p>
                            <Link to={"/PrivacyPolicy#DO-WE-COLLECT-INFORMATION-FROM-MINORS?"} 
                            scroll={(el) => handleClick(el)}
                            >DO WE COLLECT INFORMATION FROM MINORS?</Link>
                        </li>
                        <li >
                            <p>11.</p>
                            <Link to={"/PrivacyPolicy#WHAT-ARE-YOUR-PRIVACY-RIGHTS?"} 
                            scroll={(el) => handleClick(el)}
                            >WHAT ARE YOUR PRIVACY RIGHTS?</Link>
                        </li>
                        <li >
                            <p>12.</p>
                            <Link
                            to={"/PrivacyPolicy#CONTROLS-FOR-DO-NOT-TRACK-FEATURES"} 
                            scroll={(el) => handleClick(el)}
                            >CONTROLS FOR DO-NOT-TRACK FEATURES</Link>
                        </li>
                        <li >
                            <p>13.</p>
                            <Link
                            to={"/PrivacyPolicy#DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS?"} 
                            scroll={(el) => handleClick(el)}
                            >DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Link>
                        </li>
                        <li >
                            <p>14.</p>
                            <Link
                            to={"/PrivacyPolicy#DO-WE-MAKE-UPDATES-TO-THIS-NOTICE?"} 
                            scroll={(el) => handleClick(el)}
                            >DO WE MAKE UPDATES TO THIS NOTICE?</Link>

                        </li>
                        <li >
                            <p>15.</p>
                            <Link 
                            to={"/PrivacyPolicy#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?"} 
                            scroll={(el) => handleClick(el)}
                            >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>
                        </li>
                        <li >
                            <p>16.</p>
                            <Link
                            to={"/PrivacyPolicy#HOW-CAN-YOU-REVIEW-UPDATE-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU?"} 
                            scroll={(el) => handleClick(el)}
                            >HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Link>
                        </li>
                    </React.Fragment>
                </div>
            </div>
            <br />
            <div className='PP_summery' id="WHAT-INFORMATION-DO-WE-COLLECT?">
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>1.&nbsp;</h2>
                    <h2 className='PP_sub_title'> WHAT INFORMATION DO WE COLLECT?</h2>
                </div>
                <br />
                <h3 id='Personal-information-you-disclose-to-us'>Personal information you disclose to us</h3>
                <br />
                <p><b>In Short</b>: We collect personal information that you provide to us.</p>
                <br />
                <p> We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or  otherwise when you contact us.</p>
                <br />
                <p><b>Personal Information Provided by You</b>. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal  information we collect may include the following:</p>
                <br />
                <div className='PP_list_info' >
                    {personal_info.map((items) => {
                        return (
                        <div className="grid-item" >
                            <img src={images.list_icon} alt="green-tick" className='list-icon'/>
                            {items.field}
                            <div></div>
                        </div>
                        );
                    })}
                </div>
                <br/>
                <p id='Sensitive-Information'><b>Sensitive Information</b>. When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Financial data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Information revealing race or ethnic origin</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Student data</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p><b>Social Media Login Data</b>. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media accounts. If you choose to register in this way, we will  collect the information described in the section called "<Link 
                to={"/PrivacyPolicy#HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS?"} 
                scroll={(el) => handleClick(el)}> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Link>" below.</p>
                <br />
                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
                <br />
                
                <h3> Information automatically collected</h3>
                <br />
                <p> <b>In Short</b>: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
                <br />
                <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as  your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This  information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                <br />
                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                <br />
                <p>The information we collect includes:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how  you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and  files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP  address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p >Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access  the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing  access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</p>
                        </li>
                    </React.Fragment>
                </div>
            </div>
            <div className='PP_summery' id='HOW-DO-WE-PROCESS-YOUR-INFORMATION?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title' >2.&nbsp;</h2>  
                    <h2 className='PP_sub_title' >HOW DO WE PROCESS YOUR INFORMATION?</h2>
                </div>
                <br />
                <p><b>In Short</b>: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your  consent.</p>   
                <br /> 
                <p><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To facilitate account creation and authentication and otherwise manage user accounts</b>. We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To deliver and facilitate delivery of services to the user</b>. We may process your information to provide you with the requested service.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To respond to user inquiries/offer support to users</b>. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To send administrative information to you</b>.We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To request feedback</b>. We may process your information when necessary to request feedback and to contact you about your use of our Services.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To send you marketing and promotional communications</b>. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our  marketing emails at any time. For more information, see "<Link 
                            to={"/PrivacyPolicy#WHAT-ARE-YOUR-PRIVACY-RIGHTS?"} 
                            scroll={(el) => handleClick(el)}>WHAT ARE YOUR PRIVACY RIGHTS? </Link>"below.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>To save or protect an individual's vital interest</b>. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</p>
                        </li>
                    </React.Fragment>
                </div>
            </div>
            <div className='PP_summery' id='WHAT-LEGAL-BASES-DO-WE-RELY-ON-TO-PROCESS-YOUR-INFORMATION?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>3.&nbsp;</h2>
                    <h2 className='PP_sub_title'>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                </div>
                <br />
                <p><b>In Short</b>: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services  to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
                <br />
                <p><b> If you are located in the EU or UK, this section applies to you.</b></p>
                <br />
                <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your  personal information:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Consent</b>. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <Link 
                             to={"/PrivacyPolicy#Withdrawing-your-consent"} 
                             scroll={(el) => handleClick(el)}>withdrawing your consent</Link> .</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Performance of a Contract</b>. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract  with you</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Legitimate Interests</b>. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and  freedoms. For example, we may process your personal information for some of the purposes described in order to:</p>
                        </li>
                            <li> &bull;&nbsp;  Send users information about special offers and discounts on our products and services</li>
                            <li> &bull;&nbsp;  Understand how our users use our products and services so we can improve user experience</li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Legal Obligations</b>. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal  rights, or disclose your information as evidence in litigation in which we are involved.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Vital Interests</b>. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>In legal terms, we are generally the "data controller" under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This  privacy notice does not apply to the personal information we process as a "data processor" on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing  agreement is the "data controller" responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you  should read their privacy policies and direct any questions you have to them.</p>
            </div>
            <br />
            <div className='PP_summery' id='WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>4.&nbsp;</h2>
                    <h2 className='PP_sub_title'>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                </div>
                
                <br />
                <p><b>In Short</b>: We may share information in specific situations described in this section and/or with the following categories of third parties.</p>
                <br />
                <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require  access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless  we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The categories  of third parties we may share personal information with are as follows:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Government Entities</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>User Account Registration & Authentication Services</p>
                        </li>
                        <li >
                        We also may need to share your personal information in the following situations:
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Business Transfers</b>. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>When we use Google Analytics</b>. We may share your information with Google Analytics to track and analyze the use of the Services. To opt out of being tracked by Google Analytics across the Services, visit <Link to="https://tools.google.com/dlpage/gaoptout"  target='_blank' className='TC_link'>https://tools.google.com/dlpage/gaoptout</Link> . For more information on the privacy practices of Google, please visit the <Link 
                            to={"https://policies.google.com/privacy"} 
                            className='TC_link'
                            target='_blank'>Google Privacy & Terms page</Link> .</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other  companies that we control or that are under common control with us.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p><b> Business Partners</b>. We may share your information with our business partners to offer you certain products, services, or promotions.</p>
                        </li>
                    </React.Fragment>
                </div>
            </div>
            <div  className='PP_summery' id='DO-WE-USE-COOKIES-AND-OTHER-TRACKING-TECHNOLOGIES?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>5.&nbsp;</h2>
                    <h2 className='PP_sub_title'> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                </div>
                <br />
                <p><b>In Short</b>: We may use cookies and other tracking technologies to collect and store your information.</p>
                <br />
                <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie  Notice.</p>
            </div>
            <div  className='PP_summery' id='HOW-DO-WE-HANDLE-YOUR-SOCIAL-LOGINS?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>6.&nbsp;</h2>
                    <h2 className='PP_sub_title'>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                </div>
                <br />
                <p><b>In Short</b>:  If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>
                <br />
                <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your  social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose  to make public on such a social media platform</p>
                <br />
                <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other  uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy  preferences on their sites and apps.</p>
            </div>
            <div className='PP_summery' id='IS-YOUR-INFORMATION-TRANSFERRED-INTERNATIONALLY?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>7.&nbsp;</h2>
                    <h2 className='PP_sub_title'>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
                </div>
                <br />
                <p><b>In Short</b>:  We may transfer, store, and process your information in countries other than your own.</p>
                <br />
                <p>Our servers are located in Hungary. If you are accessing our Services from outside Hungary, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we  may share your personal information (see " <Link
                to={"/PrivacyPolicy#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?"} 
                scroll={(el) => handleClick(el)}> WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </Link>" above), in the United States, and other countries.</p>
                <br />
                <p>If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country.  However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
                <br />
                <p>European Commission's Standard Contractual Clauses:</p>
                <br />
                <p>We have implemented measures to protect your personal information, including by using the European Commission's Standard Contractual Clauses for transfers of personal information between our group companies and between us and  our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual  Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.</p>
            </div>
            <div className='PP_summery' id='HOW-LONG-DO-WE-KEEP-YOUR-INFORMATION?'>
                <div className='PP_tbl_title'>  
                <h2 className='PP_sub_title'>8.&nbsp;</h2>
                    <h2 className='PP_sub_title'>HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                </div>
                <br />
                <p><b>In Short</b>: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law</p>
                <br />
               <p> We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).  No purpose in this notice will require us keeping your personal information for longer than   twelve (12) months past the termination of the user's account.</p> 
               <br />
               <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in  backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
            </div>
            <div className='PP_summery' id='HOW-DO-WE-KEEP-YOUR-INFORMATION-SAFE?'>
                <div className='PP_tbl_title'>  
                    <h2 className='PP_sub_title'>9.&nbsp;</h2>
                    <h2 className='PP_sub_title'>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                </div>
                <br />
                <p><b>In Short</b>:  We aim to protect your personal information through a system of organizational and technical security measures</p>
                <br />  
                <p> We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your  information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not  be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own  risk. You should only access the Services within a secure environment.</p>
            </div>
            <div className='PP_summery' id='DO-WE-COLLECT-INFORMATION-FROM-MINORS?'>
                <div className='PP_tbl_title'>  
                    <h2 className='PP_sub_title'>10.&nbsp;</h2>
                    <h2 className='PP_sub_title'>DO WE COLLECT INFORMATION FROM MINORS?</h2>
                </div>
                <br />
                <p><b>In Short</b>:  We do not knowingly collect data from or market to children under 18 years of age.</p>
                <br />  
                <p> We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s  use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become  aware of any data we may have collected from children under age 18, please contact us at info@usaintern.club.</p>
            </div>
            <div className='PP_summery' id='WHAT-ARE-YOUR-PRIVACY-RIGHTS?'>
                <div className='PP_tbl_title'>  
                    <h2 className='PP_sub_title'>11.&nbsp;</h2>
                    <h2 className='PP_sub_title'>WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                </div>
                <br />
                <p><b>In Short</b>:   In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Switzerland, you have rights that allow you greater access to and control over your personal information. You may review, change, or  terminate your account at any time.</p>
                <br />  
                <p> In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification  or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the  processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section " <Link 
                 to={"/PrivacyPolicy#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?"} 
                 scroll={(el) => handleClick(el)}> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>" below.</p>
                <br />
                <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <br />
                <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <Link to={"https://ec.europa.eu/newsroom/article29/items/612080"} target='_blank'> Member State data protection authority</Link> or <Link to={"https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"} target='_blank'> UK data protection authority</Link>.</p>
                <br />
                <p>If you are located in Switzerland, you may contact the <Link
                to={"https://www.edoeb.admin.ch/edoeb/en/home.html"} target='_blank'> Federal Data Protection and Information Commissioner</Link>.</p>
                <br />
                <p id='Withdrawing-your-consent'><b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time.  You can withdraw your consent at any time by contacting us by using the contact details provided in the section " <Link 
                to={"/PrivacyPolicy#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?"} 
                scroll={(el) => handleClick(el)}> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>" below.</p>
                <br />
                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds  other than consent.</p>
                <br />
                <p><b>Opting out of marketing and promotional communications</b>: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying "STOP" or  "UNSUBSCRIBE" to the SMS messages that we send, or by contacting us using the details provided in the section " <Link
                to={"/PrivacyPolicy#HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?"} 
                scroll={(el) => handleClick(el)}> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>" below. You will then be removed from the marketing lists.  However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
                <br />
                <h3>Account Information</h3>
                <br />
                <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Contact us using the contact information provided.</p>
                        </li>
                    </React.Fragment>
                </div>   
                {/* <br /> */}
                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with  any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p> 
                <br />
                <p><b>Cookies and similar technologies</b>: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject  cookies, this could affect certain features or services of our Services.</p>
                <br />
                <p>If you have questions or comments about your privacy rights, you may email us at info@usaintern.club.</p>
            </div>
            <div className='PP_summery' id='CONTROLS-FOR-DO-NOT-TRACK-FEATURES'>
            <div className='PP_tbl_title'>  
            <h2 className='PP_sub_title'>12.&nbsp;</h2>
                <h2 className='PP_sub_title'>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                </div>
                <br />
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities  monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that  automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
            </div>
            <div className='PP_summery' id='DO-UNITED-STATES-RESIDENTS-HAVE-SPECIFIC-PRIVACY-RIGHTS?'>
            <div className='PP_tbl_title'>
            <h2 className='PP_sub_title'>13.&nbsp;</h2>  
                <h2 className='PP_sub_title'>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                </div>
                <br />
                <p><b>In Short</b>: If you are a resident of California, Colorado, Connecticut, Utah or Virginia, you are granted specific rights regarding access to your personal information.</p>
                <br />
                <p><b>What categories of personal information do we collect?</b></p>
                <br />
                <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
                <br />
                <br />
                <div className='PP_table_div'>
                    <table>
                        <thead>
                            <tr>
                            <th className="colored-heading col1"> Category</th>
                            <th className="colored-heading col2">Examples</th>
                            <th className="colored-heading col3">Collected</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabledata.map((row, index) => (
                            <tr key={index}>
                                <td>{row.column1}</td>
                                <td>{row.column2}</td>
                                <td className='col3'>{row.column3}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <br />
                <br />
                <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
                <br />
                <div className='PP_rights_info'>
                    {rights.map((items) => {
                        return (
                        <div className="grid-item">
                            <img src={images.list_icon} alt="green-tick" className='list-icon'/>
                            {items.field}
                        </div>
                        );
                    })}
                </div>
                <br />
                <p>Category L information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You have the right to limit the use or disclosure of your sensitive personal information.</p>
                <br />
                <p>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Receiving help through our customer support channels;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Participation in customer surveys or contests; and</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Facilitation in the delivery of our Services and to respond to your inquiries.</p>
                        </li>
                    </React.Fragment>
                </div> 
                <br />
                <p><b>How do we use and share your personal information?</b></p> 
                <br />
                <p> How do we use and share your personal information?  Learn about how we use your personal information in the section, " <Link
                to={"/PrivacyPolicy#HOW-DO-WE-PROCESS-YOUR-INFORMATION?"}
                scroll={(el) => handleClick(el)}>HOW DO WE PROCESS YOUR INFORMATION?</Link> "</p>
                <br />
                <p> We collect and share your personal information through:</p>
                <br />
                <div className='PP_rights_info'>
                    {personal_info2.map((items) => {
                        return (
                        <div className="grid-item">
                            <img src={images.list_icon} alt="green-tick" className='list-icon'/>
                            {items.field}
                        </div>
                        );
                    })}
                </div>
                <br />
                <p><b>Will your information be shared with anyone else?</b></p>
                <br />
                <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, " <Link to={"/PrivacyPolicy#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?"} 
                scroll={(el) => handleClick(el)}> WHEN AND WITH  WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Link>"</p>
                <br />
                <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.</p>
                <br />
                <p>We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category A. Identifiers</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category B. Personal information as defined in the California Customer Records law</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category C. Characteristics of protected classifications under state or federal law</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category I. Professional or employment-related information</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category J. Education information</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Category L. Sensitive personal information</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under " <Link to={"/PrivacyPolicy#WHEN-AND-WITH-WHOM-DO-WE-SHARE-YOUR-PERSONAL-INFORMATION?"} 
                scroll={(el) => handleClick(el)}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Link> "</p>
                <br />
                <p>We have sold or shared the following categories of personal information to third parties in the preceding twelve (12) months:</p>
                <br />
                <p>The categories of third parties to whom we sold personal information are:</p>
                <br />
                <h3>California Residents</h3>
                <br />
                <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal  information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California  resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
                <br />
                <p>If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please  contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but  please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
                <br />
                <h4>CCPA Privacy Notice</h4>
                <br />
                <p>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below.</p>
                <br />
                <p>The California Code of Regulations defines a "residents" as:</p>
                <br />
                <div className='PP_list1'>
                    <React.Fragment>
                        <li>
                        every individual who is in the State of California for other than a temporary or transitory purpose and
                        </li>
                        <li> every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                    </React.Fragment>
                </div>
                <br />
                <p>All other individuals are defined as "non-residents."</p>
                <br />
                <p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>
                <br />
                <p><b>Your rights with respect to your personal data</b></p>
                <br />
                <h4>Right to request deletion of the data — Request to delete</h4>
                <br />
                <p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not  limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>
                <br />
                <h4>Right to be informed — Request to know</h4>
                <br />
                <p>Depending on the circumstances, you have a right to know:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>whether we collect and use your personal information;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the categories of personal information that we collect;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the purposes for which the collected personal information is used;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>whether we sell or share personal information to third parties;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the categories of personal information that we sold, shared, or disclosed for a business purpose;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the business or commercial purpose for collecting, selling, or sharing personal information; and</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>the specific pieces of personal information we collected about you.</p>
                        </li>
                    </React.Fragment>
                </div>
                <p> In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
                <br />
                <h4>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</h4>
                <br />
                <p>We will not discriminate against you if you exercise your privacy rights.</p>
                <br />
                <h4>Right to Limit Use and Disclosure of Sensitive Personal Information</h4>
                <br />
                <p> If the business collects any of the following:</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Social security information, driver's licenses, state ID cards, passport numbers</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Account login information
                            </p >
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Credit card numbers, financial account information, or credentials allowing access to such accounts</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Precise geolocation</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Racial or ethnic origin, religious or philosophical beliefs, union membership</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>The contents of email and text, unless the business is the intended recipient of the communication</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Genetic data, biometric data, and health data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Data concerning sexual orientation and sex life</p>
                        </li>
                    </React.Fragment>
                </div>
                <p>you have the right to direct that business to limit its use of your sensitive personal information to that use which is necessary to perform the Services.</p>
                <br />
                <p>Once a business receives your request, they are no longer allowed to use or disclose your sensitive personal information for any other purpose unless you provide consent for the use or disclosure of sensitive personal information for  additional purposes.</p>
                <br />
                <p>Please note that sensitive personal information that is collected or processed without the purpose of inferring characteristics about a consumer is not covered by this right, as well as the publicly available information.</p>
                <br />
                <p>To exercise your right to limit use and disclosure of sensitive personal information, please email <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a> or visit: <a href="http://www.usaintern.club" target='_blank'>http://www.usaintern.club</a>.</p>
                <br />
                <h4> Verification process</h4>
                <br />
                <p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can  match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we  already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</p>
                <br />
                <p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However,  if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete  such additionally provided information as soon as we finish verifying you.</p>
                <br />
                <h4>Other privacy rights</h4>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>You may object to the processing of your personal information.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in  accordance with the CCPA.</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>You can opt out from the selling or sharing of your personal information by disabling cookies in Cookie Preference Settings and clicking on the Do Not Sell or Share My Personal Information link on our homepage.</p>
                <br />
                <p>To exercise these rights, you can contact us by visiting <Link to={"http://www.usaintern.club"} target='_blank'>http://www.usaintern.club</Link> , by email at 
                <a 
                href="mailto:office@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'> office@usaintern.club</a>, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your  data, we would like to hear from you.</p>
                <br />
                <h3>Colorado Residents</h3>
                <br />
                <p>This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to be informed whether or not we are processing your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to access your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to correct inaccuracies in your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to request deletion of your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to obtain a copy of the personal data you previously shared with us</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. To submit a  request to exercise any of the other rights described above, please email <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a> or visit <Link to={"http://www.usaintern.club"} target='_blank'>http://www.usaintern.club</Link></p>
                <br />
                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a>. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken  in response to the appeal, including a written explanation of the reasons for the decisions.</p>
                <br />
                <h3>Connecticut Residents</h3>
                <br />
                <p>This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted  by law.</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to be informed whether or not we are processing your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to access your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to correct inaccuracies in your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to request deletion of your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to obtain a copy of the personal data you previously shared with us</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. To submit a  request to exercise any of the other rights described above, please email <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a> or visit <a href="http://www.usaintern.club" target='_blank'>http://www.usaintern.club</a>.</p>
                <br />
                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email us at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in  response to the appeal, including a written explanation of the reasons for the decisions.</p>
                <br />
                <h3>Utah Residents</h3>
                <br />
                <p>This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.</p>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to be informed whether or not we are processing your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to access your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to request deletion of your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to obtain a copy of the personal data you previously shared with us</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to opt out of the processing of your personal data if it is used for targeted advertising or the sale of personal data</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>We sell personal data to third parties or process personal data for targeted advertising. You can opt out from the selling of your personal data or targeted advertising by disabling cookies in Cookie Preference Settings. To submit a request to  exercise any of the other rights described above, please email <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'
                      >info@usaintern.club</a> or visit <a href="http://www.usaintern.club" target='_blank'>http://www.usaintern.club</a>.</p>
                <br />
                <h3>Virginia Residents</h3>
                <br />
                <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
                <br />
                <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
                <br />
                <p>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</p>
                <br />
                <p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>
                <br />
                <p>If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
                <br />
                <h4>Your rights with respect to your personal data</h4>
                <br />
                <div className='PP_sensitive_info'>
                    <React.Fragment>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to be informed whether or not we are processing your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to access your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to correct inaccuracies in your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to request deletion of your personal data</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to obtain a copy of the personal data you previously shared with us</p>
                        </li>
                        <li >
                            <img src={images.list_icon} alt="Icon 1" className='list-icon'/>
                            <p>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")</p>
                        </li>
                    </React.Fragment>
                </div>
                <br />
                <p>We sell personal data to third parties or process personal data for targeted advertising. Please see the following section to find out how you can opt out from further selling or sharing of your personal data for targeted advertising or profiling  purposes.</p>
                <br />
                <h4>Exercise your rights provided under the Virginia VCDPA</h4>
                <br />
                <p>You can opt out from the selling of your personal data, targeted advertising, or profiling by disabling cookies in Cookie Preference Settings. You may contact us by email at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a> or visit <a href="http://www.usaintern.club" target='_blank'>http://www.usaintern.club</a>.</p>
                <br />
                <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
                <br />
                <h4>Verification process</h4>
                <br />
                <p>We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional information to verify your  identity before processing your request.</p>
                <br />
                <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will  inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
                <br />
                <h4>Right to appeal</h4>
                <br />
                <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a>. Within sixty (60) days of receipt of an appeal, we will  inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the <Link to={"https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"} target='_blank'>Attorney General to submit a complaint</Link> .</p>
            </div>
            <div className='PP_summery' id='DO-WE-MAKE-UPDATES-TO-THIS-NOTICE?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>14.&nbsp;</h2>
                    <h2 className='PP_sub_title'>DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                </div>
                <br />
                <p><b>In Short</b>: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
                <br />
                <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy  notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
            </div>
            <div className='PP_summery' id='HOW-CAN-YOU-CONTACT-US-ABOUT-THIS-NOTICE?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>15.&nbsp;</h2>
                    <h2 className='PP_sub_title'>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                </div>
                <br />
                <p>If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO), Gyongyi Veres, by email at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a>, or contact us by post at:</p>
                <br />
                <p>Usa Inter Club (Veres Gyöngyi, EV.) <br/>
                Gyongyi Veres <br />
                Szilassy út 12-14. <br />
                Budapest 1121 <br />
                Hungary
                </p>
                <br />
                <p>If you are a resident in the European Economic Area or Switzerland, we are the "data controller" of your personal information. We have appointed Gyongyi Veres to be our representative in the EEA and Switzerland. You can contact them  directly regarding our processing of your information, by email at <a 
                href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer" className='PP_blk_link'>info@usaintern.club</a>, or by post to:</p>
            </div> 
            <div className='PP_summery' id='HOW-CAN-YOU-REVIEW-UPDATE-OR-DELETE-THE-DATA-WE-COLLECT-FROM-YOU?'>
                <div className='PP_tbl_title'>
                    <h2 className='PP_sub_title'>16.&nbsp;</h2>
                    <h2 className='PP_sub_title'>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                </div>
                <br />
                <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information,  please visit: <a href="http://www.usaintern.club" target='_blank'>http://www.usaintern.club</a>.</p> 
                <br />
                <p> This privacy policy was created using Termly's <Link to={"https://termly.io/products/privacy-policy-generator/"} target='_blank'>Privacy Policy Generator.</Link> </p>
            </div>
        </div>
    </div>
  )
}

export default Privacypolicy