import React from 'react'
import "./homegallery.css"
import images from '../../constants/images'
import { useTranslation } from 'react-i18next';

const HomeGallery = () => {
  const{t} = useTranslation();
  return (
    <div className='home-gallery'>
        <div className="home-gallery-wrap">
            <div className="h-g-head">
                <p className='title1'>{t('OUR GALLERY')}</p>
                <p className='title2'>{t("Take a look at the pictures and experiences our previous participants had")}</p>
            </div>
            <div className='gallery-img-div'>
                <img src={images.gallery_img} alt="gallery_img" loading="lazy" decoding='async'/>
            </div>
        </div>
    </div>
  )
}

export default HomeGallery