import React, { useEffect, useState } from "react";

const QualifyRedirect = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading state, then redirect
    const timer = setTimeout(() => {
      window.location.href = 'https://app.usaintern.club/app/signup.html?guid=12b4b8f8-189a-4fb2-a53e-1f4428f526b2';
    }, 3000); // Simulate 3 seconds delay for loading

    return () => clearTimeout(timer); // Cleanup the timer if component unmounts
  }, []);

  return (
    <div>
      {loading && (
        <div className="loading_screen">
          <div className="loader_contact"></div>
        </div>
      )}
    </div>
  );
};

export default QualifyRedirect;
