import React from "react";
import "./contactbanner.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const Contactbanner = () => {
  const{t} = useTranslation();
  return (
    <div>
      <div
        className="contact__main_wrapp"
        // style={{ backgroundPosition: "top" }}
      >
        <img src={images.contactus_banner} alt="contact-banner-img" className="contact-banner-img" loading="lazy" decoding='async'/>
        <div className="contact__text_section_wrapp">
          <div className="contact__text_section_inner_wrapp container">
            <div className="about_hero_head_div">
              <div className="green_vr"/>
              <p className="large_shadow_text">{t("contact")}</p>
            </div>
            <p className="des_shadow_text" style={{ maxWidth: "277px" }}>
              {t("Get in touch and let us know how we can help")} 
            </p>
          </div>
        </div>
        <img src={images.herowave} alt="contact-wave" className="contact__wave_img1" />
        <div className="contact__wave_img"></div>
      </div>
    </div>
  );
};

export default Contactbanner;
