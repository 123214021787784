import React, { useRef } from 'react'
import "./testimonialreview.css"
import images from '../../constants/images'

const Testimonialreview = ({ cardDetails }) => {

    
  return (
    <div className='test2-section3'>
        <div className="test2-sec3-wrap">
            <div  className='review-slider'>
                <div className="test2-sec3-div">
                    <div className="img-div">
                        <img src={cardDetails.image} alt="card-img" loading="lazy"/>
                    </div>
                    <div className="contents">
                        <div className='quotes-div'>
                            <img src={images.left_quote} alt="quote img" />
                            <p>{cardDetails.response}</p>
                        </div>
                        <div className='quotes-div2'>
                            <p className='student-name'>{cardDetails.name}</p>
                            <p className='student-location'>{cardDetails.location}</p>
                            <p className='student-review'> {cardDetails.review}</p>
                            <p className='student-review'> {cardDetails.review1}</p>
                            <p className='student-review'> {cardDetails.review2}</p>
                            <p className='student-review'> {cardDetails.review3}</p>
                            <p className='student-review'> {cardDetails.review4}</p>
                            <p className='student-review'> {cardDetails.review5}</p>
                            <p className='student-review'> {cardDetails.review6}</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Testimonialreview