import React from "react";
import "./AboutOurMission.css";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const AboutOurMission = () => {
  const{t} = useTranslation();
  return (
    <div className="aom_main_wrapp">
      <img
        src={images.about_mession_back}
        className="aom_back_img"
        // style={{ width: "100vw", height: "100vh" }}
        alt="about_mession_back"
        loading="lazy" decoding='async'
      />
      <div className="container aom_base_wrapp">
        <div className="aom_text_wrapp">
          <p className="aboutwhatwe_orange_text">{t("GUIDING PRINCIPLES")}</p>
          <p className="aom_heading_text">{t("Our Mission & Vision")}</p>
          <p className="aom_des_text">
          {t("We strive to create opportunities that promote personal and professional growth, benefiting both participants and host communities.")}
          </p>
        </div>
        <div className="aom_squres_wrapp">
          <div className="aom_single_squre">
            <h3>{t("Our Mission")}</h3>
            <p>
              {t("Empowering educated young individuals with the chance to acquire invaluable professional and cultural experiences in the highly competitive environment of the United States of America. We firmly believe that both the foreign exchange students and the host country can gain not only professional but also personal benefits from this enriching experience.")}
            </p>
          </div>
          <div className="aom_single_squre">
            <h3>{t("Our Vision")}</h3>
            <p>
              {t("Our vision is to be the number one choice for Hungarian students and young professionals for studying or training in the USA by providing an excellent service with reliable information and advice regarding all aspects of the programs")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurMission;
