import React, { useEffect } from "react";
import {
  AboutHero,
  AboutHowCanHelp,
  AboutOurMission,
  AboutWeAre,
  AboutWhyChoose,
} from "../container";
import AboutNewsLetter from "../container/aboutNewsLetter/AboutNewsLetter";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);
  return (
    <div>
      <AboutHero />
      <AboutWeAre />
      <AboutOurMission />
      <AboutWhyChoose />
      <AboutHowCanHelp />
      <AboutNewsLetter />
    </div>
  );
};

export default AboutUs;
