import React, { useEffect, useRef, useState } from "react";
import "./homeblog.css";
import images from "../../constants/images";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Homeblog = () => {
  const [loading, setLoading] = useState(false);
  const [modalopen, setmodalopen] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef(null);
  const sliderRef = useRef(null);
  const { t, i18n } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 700,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      // {
      //   breakpoint: 900,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  // join now form
  const [validation, setValidation] = useState({
    email: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
  };
  const validateForm = () => {
    const formData = new FormData(formRef.current);
    const newemailValidation = validateEmail(formData.get("email"), t);
    
    setValidation({
        email: newemailValidation,
    });

    if (
        !newemailValidation
      ) {
        setLoading(true);
        const apiFormData = new FormData();
        apiFormData.append("email", formData.get("email"));

        axios
            .post("https://gokulnair.com/usaintern/api/add_join_request", apiFormData)
            .then((Response) => {
              setLoading(false);
                if (Response.data.status === 1) {
                    console.log("Form submitted successfully!");
                    handleSuccessModal();
                    // Clear form fields
                    formRef.current.reset();
                    setValidation({
                        email: "",
                    });
                } else {
                    console.log("Error:", Response.data.message);
                }
            })
            .catch((err) => {
              setLoading(false);
                console.log("Error:", err);
            });
        }
    };

    
    const validateEmail = (email, t) => { // Accept t as argument
      console.log("Current language:", t.language);
      if (!email) {
          const errorMessage = t("Email address is required");
          console.log("Error message:", errorMessage);
          return errorMessage;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
          const errorMessage = t("Invalid email address");
          console.log("Error message:", errorMessage);
          return errorMessage;
      }
      return "";
  };
    const handleSuccessModal = () => {
      setmodalopen(true);
     
  };
  useEffect(() => {
    if (validation.email) {
      validateForm();
    }
  }, [i18n.language]);

  return (
    <div className="home-blog">
      <div className="home-blog-wrap">
        <div className="h-blog-div">
          <div className="h-blog-content">
            <div className="h-blog-sec1">
              <p className="title">{t('Latest from Our Blogs')}</p>
              <p className="des">
                {t("Let's embark on this transformative journey of daily gratitude and unlock the power of a more joyful and fulfilling life.")}
              </p>
              <br />
              <p className="title2">{t("Join to get free updates")}</p>
              <div className="input-btn-div">
                <form ref={formRef} className="join-form">
                {/* <div class="form-group validation"> */}
                  <input 
                    type="email" 
                    name="email" 
                    placeholder={t("email address")} 
                  />
                  <button onClick={handleSubmit}>{t("Join Now")}</button>
                  {validation.email && (
                    <div className="val_1_join">{validation.email}</div>
                  )}
                  {/* </div> */}
                </form> 
              </div>
            </div>
          </div>
          <div className="h-blog-slider-div">
            <Slider {...settings} ref={sliderRef} className="h-blog-slider">

              

              <div className="recentBlogCard_main_wrapp1"
                onClick={() => {
                  navigate("/Land_Your_Dream_Internship:_Craft_an_Outstanding_U.S._Application");
                }}>
                <div className="recentBlogCard_img_wrapp1">
                  <img
                    src={images.blog_img1}
                    alt="blog_img2"
                    className="recentBlogCard_img1"
                  />
                  <img
                    src={images.blog_card_shap}
                    alt="blog_card_shape"
                    className="recentBlogCard_shap1"
                  />
                  <img
                    src={images.blog_card_arrow}
                    alt="blog_card_arrow"
                    className="recentBlogCard_arrow1"
                    
                  />
                </div>
                <div className="recentBlogCard_text_wrapp1">
                  <p className="blog-card-title">
                    Land Your Dream Internship: Craft an Outstanding U.S. Application
                  </p>
                  <p className="blog-card-del">
                  The U.S. internship scene is competitive, but with the right tools, you can make your application stand out.
                  </p>
                </div>
              </div>
              
              <div className="recentBlogCard_main_wrapp1" 
                onClick={() => {
                  navigate("/Conquering_the_Interview:_Essential_Tips_for_Nailing_Your_U.S._Internship");
                }}
              >
                <div className="recentBlogCard_img_wrapp1">
                  <img
                    src={images.blog_img3}
                    alt="blog_img3"
                    className="recentBlogCard_img1"
                  />
                  <img
                    src={images.blog_card_shap}
                    alt="blog_card_shap"
                    className="recentBlogCard_shap1"
                  />
                  <img
                    src={images.blog_card_arrow}
                    alt="blog_card_arrow"
                    className="recentBlogCard_arrow1"
                    
                  />
                </div>
                <div className="recentBlogCard_text_wrapp1">
                  <p className="blog-card-title">
                  Conquering the Interview: Essential Tips for Nailing Your U.S. Internship
                  </p>
                  <p className="blog-card-del">
                  Landing a U.S. internship interview is a fantastic achievement. Now comes the next hurdle: acing the interview itself.
                  </p>
                </div>
              </div>

              <div className="recentBlogCard_main_wrapp1" 
                onClick={() => {
                  navigate("/Navigating_the_J1_Visa_Process_Made_Simple_with_USAINTERN.CLUB");
                }}
              >
                <div className="recentBlogCard_img_wrapp1">
                  <img
                    src={images.blog_img2}
                    alt="blog_img3"
                    className="recentBlogCard_img1"
                  />
                  <img
                    src={images.blog_card_shap}
                    alt="blog_card_shap"
                    className="recentBlogCard_shap1"
                  />
                  <img
                    src={images.blog_card_arrow}
                    alt="blog_card_arrow"
                    className="recentBlogCard_arrow1"
                    
                  />
                </div>
                <div className="recentBlogCard_text_wrapp1">
                  <p className="blog-card-title">
                  Navigating the J-1 Visa Process Made Simple with Usa Intern Club
                  </p>
                  <p className="blog-card-del">
                  Embarking on a cultural exchange program in the U.S. through the J-1 visa can be an exciting journey.
                  </p>
                </div>
              </div>

              <div className="recentBlogCard_main_wrapp1" 
                onClick={() => {
                  navigate("/Navigating_J1-Visa_Taxes:_A_Complete_Guide_To_Filing_Your_U.S._Tax_Return");
                }}>
                <div className="recentBlogCard_img_wrapp1" >
                  <img
                    src={images.taxbanner_blog}
                    alt="blog_img1"
                    className="recentBlogCard_img1"
                  />
                  <img
                    src={images.blog_card_shap}
                    alt="blog_card_shape"
                    className="recentBlogCard_shap1"
                  />
                  <img
                    src={images.blog_card_arrow}
                    alt="blog_card_arrow"
                    className="recentBlogCard_arrow1"
                    
                  />
                </div>
                <div className="recentBlogCard_text_wrapp1">
                  <p className="blog-card-title">
                  Navigating J-1-Visa Taxes: A Complete Guide To Filing Your U.S. Tax Return
                  </p>
                  <p className="blog-card-del">
                  You will require a specialized tax return form rather than a standard one. Rely on our trusted partner <Link to={"https://taxreturn4you.com/"} target="_blank" >TaxReturn4You.</Link> 
                  </p>
                </div>
              </div>
             
            </Slider>
            <div className="blog-arrow-div">
              <div className="blog-slider-button-div">
                <button
                  onClick={handlePrevSlide}
                  className="blog-slider-button"
                >
                  <img src={images.arrow_left} alt="slider_left_arrow" />
                </button>
                <button
                  onClick={handleNextSlide}
                  className="blog-slider-button"
                >
                  <img src={images.arrow_right} alt="slider_right_arrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalopen === true ? (
                <div className="modal_screen">
                    <div className="" style={{ textAlign: "end" }}>
                    <AiOutlineClose
                        className="modal_close"
                        onClick={() => setmodalopen(false)}
                    />
                    </div>
                    <img className="body_img" src={images.check} alt="check" />
                    <h1 className="body_h1">Success</h1>
                </div>
                ) : (
                ""
        )}
            {loading && (
        <div className="loading_screen">
          <div className="loader_contact"></div>
          {/* <img src={images.Spinnerr} alt="Spinner" /> */}
        </div>
      )}
    </div>
  );
};

export default Homeblog;
