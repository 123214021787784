import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import en from "../lng/en.json"
import hu from "../lng/hu.json"

// const languages=['en','hu']
i18n
// .use(Backend)
// .use(LanguageDetector)
.use(initReactI18next)
// .use(Backend)
.init({
  // supportedLangs:['en','hu'],
  load: 'languageOnly',
    fallbackLang: 'en',
    debug: true,
    lng:"en",
    // whitelist: languages,
   saveMissing:true,
   interpolation:{
    escapeValue:false
   },
   resources:{
    en:{
      translation:en,
    },
    hu:{
      translation: hu,
    }
   }
  //  backend:{
  //   loadPath:"/locales/{{lng}}/translation.json"
  //  }
  });

  export default i18n;