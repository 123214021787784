import React, { useRef } from 'react'
import "./homeplacement.css"
import images from '../../constants/images'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';

const Homeplacement = () => {
    const sliderRef = useRef(null);
    const{t} = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 700,
        autoplay:true,
        autoplaySpeed: 3700,
        arrows:false,
        responsive: 
        [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  dots:true,
                },
              },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots:true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };
    
      const handleNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handlePrevSlide = () => {
        sliderRef.current.slickPrev();
    };
  return (
    <div className='home-section3'>
        <div className='h-sec3-div'>
            <div className='home-sec3-title'>
                <p className='h-secc3-title1'>{t('UNLOCK YOUR POTENTIAL')}</p>
                <p className='h-secc3-title2'>{t('Explore Placement Fields')}</p>
            </div>
            <div className='h-sec3-slider'>
                <Slider {...settings} ref={sliderRef }className="department">
                    <div className='dept-col1'>
                        <div className='dept-card'>
                            <img src={images.hr_img} alt="HR" loading="lazy" decoding='async' />
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>HR</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t("Recruitment")}</li>
                                   <li>{t("Training & development")}</li>
                                   <li>{t("Compensation & benefits")}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className='dept-card'>
                            <img src={images.graphic_img} alt="Graphic Design" loading="lazy" decoding='async' />
                              <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Graphic Design")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t("Web design")}</li>
                                   <li>{t("Print design")}</li>
                                   <li>{t("Branding design")}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='dept-col2'>
                        <div className='dept-card2'>
                            <img src={images.business_img} alt="Business" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                                <div className='dept-title'>
                                    <p  className='dept-title1'>Business</p>
                                    <p className='dept-des'>
                                    <ul className="star-list">
                                        <li>{t("Management")}</li>
                                       <li>{t("Project management")}</li>
                                       <li>{t("Sales")}</li>
                                    </ul>
                                    </p>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div className='dept-col1'>
                        <div className='dept-card'>
                            <img src={images.marketting_img} alt="Marketting" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                                <div className='dept-title'>
                                    <p  className='dept-title1'>Marketing</p>
                                    <p className='dept-des'>
                                    <ul className="star-list">
                                        <li>{t('Online marketing')}</li>
                                       <li>{t('Brand marketing')}</li>
                                       <li>{t('PR')}</li>
                                    </ul>
                                    </p>
                                </div>
                                </div>
                            </div>
                        <div className='dept-card'>
                            <img src={images.agriculture_img} alt="Agriculture" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Agriculture")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Crops, fruits & vegetables')}</li>
                                   <li>{t('Animal farming')}</li>
                                   <li>{t('Dairy')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='dept-col2'>
                        <div className='dept-card2'>
                            <img src={images.hospitality_fb_img}  alt="Hospitality : F&B" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Hospitality : F&B")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Taking orders')}</li>
                                    <li>{t('Serving customers')}</li>
                                    <li>{t('Maintaining cleanliness')}</li>
                                    <li>{t('High-quality customer service standards')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='dept-col1'>
                        <div className='dept-card'>
                            <img src={images.sales_img} alt="Sales" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Sales")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                  <li>{t('Products & services')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className='dept-card'>
                            <img src={images.it_img} alt="IT" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p className='dept-title1'> IT</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                  <li>{t('Software development')}</li>
                                  <li>{t('Web development')}</li>
                                  <li>{t('System administration')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='dept-col2'>
                        <div className='dept-card2'>
                            <img src={images.hospitality_front_480} alt="Hospitality : Front Desk" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Hospitality : Front Desk")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Guest check-in/check-out')}</li>
                                    <li>{t('Inquiries')}</li>
                                    <li>{t('Reservations')}</li>
                                    <li>{t('Hotel amenities')}</li>
                                    <li>{t('Local attractions')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='dept-col1'>
                        <div className='dept-card'>
                            <img src={images.culninary_img}  alt="Culinary" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Culinary")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Food preparation tasks')}</li>
                                    <li>{t('Professional kitchen operations')}</li>
                                    <li>{t('Meal presentation')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className='dept-card'>
                            <img src={images.finance_img}alt="Finance" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Finance")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Corporate finance')}</li>
                                    <li>{t('Financial planning')}</li>
                                    <li>{t('Financial analysis')}</li>                                    
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className='dept-col2'>
                        <div className='dept-card2'>
                            <img src={images.hospitality_img} alt="Hospitality : Rooms" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Hospitality : Rooms")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Room preparation')}</li>
                                    <li>{t('Cleaning')}</li>
                                    <li>{t('Maintenance')}</li>
                                    <li>{t('Guest comfort')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='dept-col1'>
                        <div className='dept-card'>
                            <img src={images.mechanical_img} alt="Mechanical Engineering" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Mechanical Engineering")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Automotive engineering')}</li>
                                    <li>{t('Robotics')}</li>
                                    <li>{t('Manufacturing engineering')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className='dept-card'>
                            <img src={images.architecture_img} alt="Architecture" loading="lazy" decoding='async'/>
                            <div className="overlay1">
                            <div className='dept-title'>
                                <p  className='dept-title1'>{t("Architecture")}</p>
                                <p className='dept-des'>
                                <ul className="star-list">
                                    <li>{t('Residential')}</li>
                                    <li>{t('Commercial & Industrial')}</li>
                                    <li>{t('Permitting')}</li>
                                </ul>
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                </Slider>
                <div className='slider-btn'>
                <button onClick={handlePrevSlide}>
                    <img src={images.arrow_left} alt="arrow_left" />
                </button>
                <button onClick={handleNextSlide}>
                    <img src={images.arrow_right} alt="arrow_right" />
                </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Homeplacement