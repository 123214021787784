import React, { createContext, useContext, useState } from 'react';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0); // Initialize with default scroll position

  return (
    <GlobalStateContext.Provider value={{ scrollPosition, setScrollPosition }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);