import React, { useRef } from 'react'
import "./homereview.css"
import images from '../../constants/images'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

const Homereview = () => {
    // const studentData = [
    //     {
    //       name: 'Dietrich',
    //       place: "Germany",
    //       review:"In the 18 months that the program lasted I learned more about myself and the business world than any other university degree could have ever taught me.",
    //     },
    //     {
    //         name: 'Györgyi',
    //         place: "Hungary",
    //         review:"An internship abroad gives you the perfect opportunity to enjoy a different country while filling your bag with exceptional experiences and broaden your horizon at the same time.",
    //       },
    //       {
    //         name: 'Juan',
    //         place: "Germany",
    //         review:"In the 18 months that the program lasted I learned more about myself and the business world than any other university degree could have ever taught me.",
    //       },
    // ]
    const sliderRef = useRef(null);
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 700,
        autoplay:true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots:true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const handleNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handlePrevSlide = () => {
        sliderRef.current.slickPrev();
    };
    const handleCardClick = (data) => {
        navigate("/TestimonialDetail", { state: { cardDetails: data } });
        setTimeout(() => {
            // Get the element by id and scroll into view
            const testimonialReviewSection = document.getElementById("testimonialReview");
            const navbarHeight = 84.2;
            if (testimonialReviewSection) {
              window.scrollTo({
                top: testimonialReviewSection.offsetTop - navbarHeight,
                behavior: "smooth",
              });
            }
          }, 100);
      };
      
  return (
    <div className='home-section6'>
        <div className="home-sec6-wrap">
            <div className="button-slider">
                {/* <div className='slider-btn-div'>
                    <button onClick={handlePrevSlide}>
                        <img src={images.arrow_left} alt="arrow_left" />
                    </button>
                </div>  */}
                <div className='h-sec6-slider-div'>
                    <Slider {...settings} ref={sliderRef } className="h-sec6-slider">
                        <div 
                             className="student-cards" 
                            onClick={() => handleCardClick({
                                image: images.student_img1,
                                name: 'Dietrich',
                                location: 'Germany',
                                response: 'I´ll never forget this great experience and I would suggest for  everyone, who has an option to do an internship abroad, to take the  chance and have your time of your life.',
                                review:"I decided to do an internship in the states for 18 months and it was the best thing that I ever did till now.",
                                review1:"The working experience was different from the one at home, and I was able to get a lot of new and exciting experience for my private and my business future.",
                                review2:'I have chosen the states because I was always really interested in how the life will be and I wanted to figure out how different the people and the culture will be over the big sea. I was not disappointed at all.',
                                review3:"I had the chance to make a lot of new friends from all over the world. In my case it was around 20 different nationalities. We spent a lot of leisure time together and did a lot of different things.",
                                review4:'That was for example visiting the National Park “Everglades”, going to a basketball game to see Miami Heat, sightseeing in Miami, Orlando and Key West and a lot more funny and unforgettable things.',
                                review5:'Also I had the chance to see even more from the states, because everybody gets a “grace period” after the internship is done. This means that you can travel around for one month – like a big vacation. I did that and I visited San Francisco, Las Vegas, Grand Canyon, Los Angeles, Hoover Dam, Phoenix, Washington D.C. and New York City.',
                                review6:"I´ll never forget this great experience and I would suggest for everyone, who has an option to do an internship abroad, to take the chance and have your time of your life."
                              })}
                        >
                            <div className='student-img'>
                                <img src={images.student_img1} alt="Dietrich" loading="lazy" decoding='async'/>
                            </div>
                            <div className='student-card1-des'>
                            <img src={images.card1_wave} alt="wave-img" className='imgg'/>
                                <p className='student-name'>Dietrich</p>
                                <p className='student-location'>Germany</p>
                                <p className='student-response'>I´ll never forget this great experience and I would suggest for  everyone, who has an option to do an internship abroad, to take the  chance and have your time of your life.</p>
                                <div className='student-readmore'>
                                    <p>Read their story</p>
                                </div>
                            </div>
                        </div>
                        <div 
                             className="student-cards" 
                             onClick={() => handleCardClick({
                                image: images.student_img2,
                                name: 'Györgyi',
                                location: 'Hungary',
                                response: 'An internship abroad gives you the perfect opportunity to enjoy a different country while filling your bag with exceptional experiences and broaden your horizon at the same time.',
                                review:"I was able to spend 18 months as a marketing trainee in Alaska; it was amazing. Based on my experience as a trainee I would highlight 3 major benefits of the trainee/internship: professional growth and self-understanding, friendships.Working with new people from different cultures generates bonds that only get tighter as the year progresses, I got their support in everything.",
                                review1:"The 30 days grace period at the end of the program is a bless, you will never get bored of living new adventures with every person you meet. My vision of the world got wider and humbler. My thought is that it should be mandatory for everyone to take advantage of this opportunity! It’s normal to be afraid, but you’ll never regret taking this chance."
                              })}
                        >
                            <div className='student-img'>
                                    <img src={images.student_img2} alt="Györgyi" loading="lazy" decoding='async'/>
                            </div>
                            <div className='student-card2-des'>
                                <img src={images.card2_wave} alt="wave-img" className='imgg'/>
                                <p className='student-name'>Györgyi</p>
                                <p className='student-location'>Hungary</p>
                                <p className='student-response'>An internship abroad gives you the perfect opportunity to enjoy a different country while filling your bag with exceptional experiences and broaden your horizon at the same time.</p>
                                <div className='student-readmore'>
                                    <p>Read their story</p>
                                </div>
                            </div>
                        </div>
                        <div 
                             className="student-cards" 
                            onClick={() => handleCardClick({
                                image: images.student_img3,
                                name: 'Juan',
                                location: 'Colombia',
                                response: 'A few months ago I finished my internship in the USA and I can say that was one of the most amazing experiences that I had in my life until this day.',
                                review:' A few months ago I finished my internship in the USA and I can say that was one of the most amazing experiences that I had in my life until this day.',
                                review1:'I had my internship in Florida, USA, and I wasn’t working a seasonal job, the internship was in my field of study, I am a Software Developer.',
                                review2:
                                'It was a really good experience where I learned a lot, for example, how to live on my own, since I didn’t have any family close to me so I had to prove it to myself, that I was able to live alone without any problem. You will probably not be the only Intern in the zone, I had the opportunity to share my culture with people from other countries as well, like Germany, Hungary, Lithuanian, China, Serbia and some others, and in that time they will be your family. The professional experience that you will gain can help you a lot, it is nothing better for your knowledge to try different perspectives, and working in another culture will give you that extra.',
                                review3:
                                'But the most important here, is that doing an internship in another country will open doors for you in your country, because what is better than writing in your curriculum vitae that you gained experience in an international enterprise in your field abroad.',
                                review4:
                                'Another good thing about this experience is that I want to continue traveling to learn more, to see other perspectives from other countries, right now I am working on my new Project, to earn a master’s degree in Germany, and I can’t wait to be there.',
                                review5:
                                'P.S: the cost of the program was nothing compared to the experience. Think about it … you will go for 18 months to another country, you will probably receive compensation for learning, you will practice a new language (if you are lucky even more than one) and you could go to a different parts of the country to see new things.'
                              })}
                        >
                            <div className='student-img'>
                                <img src={images.student_img3} alt="Juan" loading="lazy" decoding='async'/>
                            </div>
                            <div className='student-card3-des'>
                            <img src={images.card3_wave} alt="wave-img" className='imgg'/>
                                <p className='student-name'>Juan</p>
                                <p className='student-location'>Colombia</p>
                                <p className='student-response'>A few months ago I finished my internship in the USA and I can say that was one of the most amazing experiences that I had in my life until this day.</p>
                                <div className='student-readmore'>
                                    <p>Read their story</p>
                                </div>
                            </div>
                        </div>
                        <div 
                             className="student-cards" 
                            onClick={() => handleCardClick({
                                image: images.student_img4,
                                name: 'Seline',
                                location: 'Switzerland',
                                response: 'When I got accepted for an internship placement in Florida I couldn’t  imagine back then, that this would be one of the greatest experiences of  my life.',
                                review:"When I heard that one of my friends was going to do an internship in the USA, I couldn’t help but feeling a little bit jealous.",
                                review1:"This is when I asked myself: Why am I not doing this? When I got accepted for an internship placement in Florida I couldn’t imagine back then, that this would be one of the greatest experiences of my life.",
                                review2:"Next to gaining valuable work experience and being able to improve my English skills on a daily basis, I met wonderful people, learned about a different culture and enjoyed my life with everything that Florida has to offer every day to the fullest.",
                                review3:"An internship abroad gives you the perfect opportunity to enjoy a different country while filling your bag with exceptional experiences and broaden your horizon at the same time. What are you waiting for?",
                              })}
                        >
                            <div className='student-img'>
                                    <img src={images.student_img4} alt="Seline" loading="lazy" decoding='async'/>
                            </div>
                            <div className='student-card4-des'>
                            <img src={images.card4_wave} alt="wave-img" className='imgg'/>
                                <p className='student-name'>Seline</p>
                                <p className='student-location'>Switzerland</p>
                                <p className='student-response'>When I got accepted for an internship placement in Florida I couldn’t  imagine back then, that this would be one of the greatest experiences of  my life.</p>
                                <div className='student-readmore'>
                                    <p>Read their story</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            className="student-cards"   
                            onClick={() => handleCardClick({
                                image: images.student_img5,
                                name: 'Sergio',
                                location: 'South Africa',
                                response: 'In the 18 months that the program lasted I learned more about myself and the business world than any other university degree could have ever taught me.',
                                review:'I was fortunate enough to be placed at a fast growing small American business with huge potential for learning and experiencing the very real entrepreneurial interactions.',
                                review1:"In the 18 months that the program lasted I learned more about myself and the business world than any other university degree could have ever taught me.",
                                review2:"The program also allowed me to expand my personal and business networks and I now have great friends/associates all over the world.",
                                review3:"Truly an amazing and enriching experience!"
                              })}
                        >
                            <div className='student-img'>
                                <img src={images.student_img5} alt="Sergio" loading="lazy" decoding='async'/>
                            </div>
                            <div className='student-card5-des'>
                            <img src={images.card5_wave} alt="wave-img" className='imgg'/>
                                <p className='student-name'>Sergio</p>
                                <p className='student-location'>South Africa</p>
                                <p className='student-response'>In the 18 months that the program lasted I learned more about myself and the business world than any other university degree could have ever taught me.</p>
                                <div className='student-readmore'>
                                    <p>Read their story</p>
                                </div>
                            </div>
                        </div>
                        
                    </Slider>
                    <div className='slider-btn-review'>
                        <button onClick={handlePrevSlide}>
                            <img src={images.arrow_left} alt="arrow_left" />
                        </button>
                        <button onClick={handleNextSlide}>
                            <img src={images.arrow_right} alt="arrow_right" />
                        </button>
                    </div>
                </div>     
            {/* <div className='slider-btn-div2'>
                <button onClick={handleNextSlide}>
                    <img src={images.arrow_right} alt="arrow_right" />
                </button>
            </div> */}
            </div>
        </div>
    </div>
  )
}

export default Homereview