import React, { useRef, useState } from "react";
import "./Navbar.css";
import images from "../../constants/images";
import {  Link, useLocation } from "react-router-dom";
import { CustomDropdown } from "../../components";
// import { Link } from 'react-scroll';
import { HashLink as Link1 } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../../GlobalStateContext";
const customStyles = {
  
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    backgroundColor: "none",
    border: "none",
    borderRadius: "0px",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};

const Navbar = () => {
  const dropdownRef = useRef(null);

  const location = useLocation();

  const [getsidebarOpen, setSidebarOpen] = useState(false);

  const{t} = useTranslation();


  const handleChange = () => {
    setSidebarOpen(!getsidebarOpen);

  };

  const { setScrollPosition, scrollPosition } = useGlobalState();
// Function to update scroll position to 0 (top) when Contact Us is clicked
const handleContactUsClick = () => {
  setScrollPosition(0);
  
};

// Function to update scroll position to 1 (bottom) when Apply Now is clicked
const handleApplyNowClick = () => {
  setScrollPosition(1);
  setSidebarOpen(!getsidebarOpen);
};
 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSideDropdownOpen, setSideIsDropdownOpen] = useState(false);
  const handleProgramOpen = () => {
    setSideIsDropdownOpen(!isSideDropdownOpen);

  };

 


  let timeoutId;
  const handleDropdownToggle = () => {
    setIsDropdownOpen(true);
    clearTimeout(timeoutId); // Clear timeout if dropdown is opened again
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' // Use smooth scrolling
    });
  };

  const [initialElementPosition, setInitialElementPosition] = useState(null);

  const [initialOffsetPosition, setInitialOffsetPosition] = useState(null);

  const [elementPosition, setElementPosition] = useState(null);
  console.log("initial",elementPosition);

  // for activate tax link
const currentDate = new Date();
const january2025 = new Date('2025-01-01');

const isActive = currentDate >= january2025;
  

const getApplyNowLink = () => {
  if (location.pathname === "/InternshipUSA") {
    return "https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=internship";
  } else if (location.pathname === "/ProfessionalCareerTrainingUSA") {
    return "https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=professionaltraining";
  }else if (location.pathname === "/AuPairProgram") {
    return "/contactus";
  }
  else if (location.pathname === "/VirtualInternship") {
    return "https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=virtualinternship";
  }
  else if (location.pathname === "/STEPUSA") {
    return "https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=step";
  } else {
    // Default link if not on a specific page
    return "https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html&proute=homepage";
  }
};
console.log(getApplyNowLink()); // Log the URL to debug
  return (
    <>
      <nav className="nav_main_wrapp">
        <div className="container">
          <div className="nav_base_wrapp">
            <Link to={"/"}>
              <img src={images.navlogo} alt="logo" className="nav_logo" />
            </Link>

            <div className="nav_links">
              <Link
                to={"/"}
                style={{
                  color:
                    location.pathname === "/"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={() => {
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                {t('home')}
                {/* Home */}
              </Link>
              <Link
                to={"/about"}
                style={{
                  color:
                    location.pathname === "/about"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/about" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={() => {
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                 {t('about')}
                {/* About Us */}
              </Link>
              <div className="nav_dropdown_wrapper" 
              ref={dropdownRef}
                // onMouseEnter={handleDropdownToggle}
                onMouseLeave={handleMouseLeave}
                >
                <Link
                  to={"/programs"}
                  onMouseEnter={handleDropdownToggle}
                  
                  style={{
                    color:
                      location.pathname === "/programs"
                        ? "var(--color-sky-blue)"
                        : "black",
                    fontWeight: location.pathname === "/programs" ? "600" : "600",
                    fontFamily: "var(--font-base)",
                  }}
                >
                   {t('programs')}
                  {/* Programs */}
                </Link>
                {isDropdownOpen && (
                  <div
                    className="nav_dropdown_content"
                    onMouseEnter={handleDropdownToggle}
                  >
                    <Link
                      to={"/InternshipUSA"}
                      onClick={handleDropdownClose} // Close dropdown after selection
                    >
                      {t('internship usa')}
                      {/* Internship USA */}
                    </Link>
                    <Link
                      to={"/ProfessionalCareerTrainingUSA"}
                      onClick={handleDropdownClose} // Close dropdown after selection
                    >
                       {t('professional career training usa')}
                      {/* Professional Career Training USA */}
                    </Link>
                    <Link
                      to={"/AuPairProgram"}
                      onClick={handleDropdownClose} // Close dropdown after selection
                    >
    
                      {/* AU-PAIR USA */}
                      {t("au pair usa")}
                    </Link>
                    <Link
                      to={"/VirtualInternship"}
                      onClick={handleDropdownClose} // Close dropdown after selection
                    >
                      {/* Virtual Internship */}
                      {t("virtual internship")}
                    </Link>
                    <Link
                      to={"/STEPUSA"}
                      onClick={handleDropdownClose} // Close dropdown after selection
                    >
                      {t("step usa")}
                      {/* STEP USA */}
                    </Link>
                  </div>
                )}
              </div>
              
              <Link
                to={"/Blog"}
                style={{
                  color:
                    location.pathname === "/Blog"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/Blog" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={() => {
                  scrollToTop(); // Scroll to top when clicking the link
                }}
              >
                 {t('Blog')}
                {/* Blog */}
              </Link>
              {isActive && (
                <Link
                  to="/TaxReturn"
                  style={{
                    color: location.pathname === '/TaxReturn' ? 'var(--color-sky-blue)' : 'black',
                    fontWeight: location.pathname === '/TaxReturn' ? '600' : '600',
                    fontFamily: 'var(--font-base)',
                  }}
                  onClick={handleChange}
                >
                  {t('taxreturn')}
                </Link>
              )}

              {/* Optionally, render nothing if not active */}
              {!isActive && null}
              <Link
               to={"/contactus"}
               state={{ scroll: location.pathname === "/contactus" ? "true" : "false" }}
               style={{
                 color:
                   location.pathname === "/contactus"
                     ? "var(--color-sky-blue)"
                     : "black",
                 fontWeight:
                   location.pathname === "/contactus" ? "600" : "600",
                 fontFamily: "var(--font-base)",
               }}
               onClick={() => {
                 handleContactUsClick();
                 scrollToTop(); // Scroll to top when clicking the link
               }}
              >
                 {t('contact')}
                {/* Contact Us */}
              </Link>
            </div>

            <div className="nav_links_apply_wrapp">
             
              <CustomDropdown />
              <Link1 
                to={getApplyNowLink()}
                //  to="/contactus" 
                // state={{ scroll: 'true' }}
                className="nav_blue_btn"
                // onClick={handleApplyNowClick}
                target="_blank"
              >
                {/* Apply Now */}
                {t('apply now')}
              </Link1>
            </div>
    
            <input
              id="checkbox"
              type="checkbox"
              checked={getsidebarOpen}
              onClick={() => {
                setSidebarOpen(!getsidebarOpen);
              }}
            />
            <label className="toggle" htmlFor="checkbox">
              <div id="bar1" className="bars"></div>
              <div id="bar2" className="bars"></div>
              <div id="bar3" className="bars"></div>
            </label>
          </div>

          {/* ----------- sidemenu start ------------ */}
          <div
            className={`nav_sidebar_wrapp`}
            style={{
              width: getsidebarOpen ? "100vw" : "0px",
              height: getsidebarOpen ? "90vh" : "0px",
              transition: "height 0.5s ease",
              overflow: "hidden",
            }}
          >
            <div className="navbar_inner_wrapp_1">
              <div className="navbar_inner_wrapp">
              <Link
                to={"/"}
                style={{
                  color:
                    location.pathname === "/"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                {/* Home */}
                {t('home')}
              </Link>
              <Link
                to={"/about"}
                style={{
                  color:
                    location.pathname === "/about"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/about" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
                {/* About Us */}
                {t('about')}
              </Link>
         
                <Link
                  to={"/programs"}
                  className="nav_dropdown_title"
                  // onClick={() => setSideIsDropdownOpen(true)}
                  onClick={handleProgramOpen}
                >
                  {/* Programs */}
                  {t('programs')}
                </Link>
                {isSideDropdownOpen && (
                  <div className="nav_dropdown_content_side">
                    <Link 
                      to={"/InternshipUSA"}
                      onClick={handleChange}
                      style={{
                        color:
                          location.pathname === "/InternshipUSA"
                            ? "var(--color-sky-blue)"
                            : "black",
                        fontWeight:
                          location.pathname === "/InternshipUSA" ? "600" : "600",
                        fontFamily: "var(--font-base)",
                      }}
                    >
                     {t('internship usa')}
                    </Link>

                    <Link 
                      to={"/ProfessionalCareerTrainingUSA"}
                      onClick={handleChange}
                      style={{
                        color:
                          location.pathname === "/ProfessionalCareerTrainingUSA"
                            ? "var(--color-sky-blue)"
                            : "black",
                        fontWeight:
                          location.pathname === "/ProfessionalCareerTrainingUSA" ? "700" : "600",
                        fontFamily: "var(--font-base)",
                      }}
                    >
                      {t('professional career training usa')}
                    </Link>
                    <Link 
                      to={"/AuPairProgram"}
                      onClick={handleChange}
                      style={{
                        color:
                          location.pathname === "/AuPairProgram"
                            ? "var(--color-sky-blue)"
                            : "black",
                        fontWeight:
                          location.pathname === "/AuPairProgram" ? "700" : "600",
                        fontFamily: "var(--font-base)",
                      }}
                    >
                     {t("au pair usa")}
                    </Link>
                    <Link 
                      to={"/VirtualInternship"}
                      onClick={handleChange}
                      style={{
                        color:
                          location.pathname === "/VirtualInternship"
                            ? "var(--color-sky-blue)"
                            : "black",
                        fontWeight:
                          location.pathname === "/VirtualInternship" ? "700" : "600",
                        fontFamily: "var(--font-base)",
                      }}
                    >
                      {t("virtual internship")}
                    </Link>
                    <Link 
                      to={"/STEPUSA"}
                      onClick={handleChange}
                      style={{
                        color:
                          location.pathname === "/STEPUSA"
                            ? "var(--color-sky-blue)"
                            : "black",
                        fontWeight:
                          location.pathname === "/STEPUSA" ? "700" : "600",
                        fontFamily: "var(--font-base)",
                      }}
                    >
                      {t("step usa")}
                    </Link>
                    {/* Add more program links here */}
                  </div>
                  )}  
              
              <Link
                to={"/Blog"}
                style={{
                  color:
                    location.pathname === "/Blog"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight: location.pathname === "/Blog" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={handleChange}
              >
               {t('Blog')}
              </Link>
              {isActive && (
                <Link
                  to="/TaxReturn"
                  style={{
                    color: location.pathname === '/TaxReturn' ? 'var(--color-sky-blue)' : 'black',
                    fontWeight: location.pathname === '/TaxReturn' ? '600' : '600',
                    fontFamily: 'var(--font-base)',
                  }}
                  onClick={handleChange}
                >
                  {t('taxreturn')}
                </Link>
                 )}

                 {/* Optionally, render nothing if not active */}
                 {!isActive && null}
              <Link
                to={"/contactus"}
                // state={{scroll: 'false'}}
                style={{
                  color:
                    location.pathname === "/contactus"
                      ? "var(--color-sky-blue)"
                      : "black",
                  fontWeight:
                    location.pathname === "/contactus" ? "600" : "600",
                  fontFamily: "var(--font-base)",
                }}
                onClick={() => {
                  handleContactUsClick();
                  scrollToTop(); // Scroll to top when clicking the link
                  handleChange();
                }}
              >
                {t('contact')}
              </Link>
              </div>

              <div className="navbar_inner_wrapp_2">
                <CustomDropdown />
                <Link1
                  to={getApplyNowLink()}
                  target="_blank"
                  //  to="/contactus" 
                  //  state={{scroll: 'true'}}
                  className="side_nav_blue_btn"
                  // onClick={handleApplyNowClick}
                >
                   {t('apply now')}
                </Link1>
              </div>
            </div>
          </div>
          {/* ----------- sidemenu end ------------ */}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
