import React, { useEffect } from 'react'
import Homebanner from '../container/homeBanner/Homebanner'
import Homecultural from '../container/homeCulturalprgm/Homecultural'
import Homeplacement from '../container/homePlacement/Homeplacement'
import HomeAbout from '../container/HomeAbout/HomeAbout'
import HomeGallery from '../container/HomeGallery/HomeGallery'
import Joinstudents from '../container/homeJoinStudents/Joinstudents'
import Homereview from '../container/homeReview/Homereview'
import Homeblog from '../container/HomeBlog/Homeblog'
import HomeQuestions from '../container/HomeQuestions/HomeQuestions'

const Home = () => {


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div>
        <Homebanner/>
        <Homecultural/>
        <Homeplacement/>
        <HomeAbout/>
        <HomeGallery/>
        <Joinstudents/>
        <Homereview/>
        <Homeblog/>
        <HomeQuestions/>
        {/* <Homegetintouch /> */}
    </div>
  )
}

export default Home