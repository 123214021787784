import React from 'react'
import "./joinstudents.css"
import images from '../../constants/images'
import { useTranslation } from 'react-i18next';

const Joinstudents = () => {
    const{t} = useTranslation();
  return (
    <div className='join-students'>
        <div className='join-students-wrap'>
            <div className="bg-img">
                <img src={images.student_bg} alt="student_image" />
            </div>
            <div className="student-content">
                <p className='title'>{t('Join Over 5 Million Students')}</p><br/>
                <p className='des'>{t("Don't miss out on the opportunity to expand your horizons and join a community dedicated to learning and growth.")}</p>
                <div className='student-count'> 
                    <div className='count-sec'>
                        <p className='pink-text'>12 +</p>
                        <p className='count-des'>{t("Months Internship")}</p>
                    </div>
                    <div className='count-sec'>
                        <p className='blue-text'>10 +</p>
                        <p className='count-des'>{t("Fields")}</p>
                    </div>
                    <div className='count-sec'>
                        <p className='green-text'>1000 +</p>
                        <p className='count-des'>{t("Unforgettable memories")}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Joinstudents