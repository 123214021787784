import React, { useEffect } from 'react'
import Testimonialbanner from '../container/testimonialBanner/Testimonialbanner'
import Homereview from '../container/homeReview/Homereview'
import Testimonialreview from '../container/testimonialReview/Testimonialreview'

const Testimonials = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, []);
  return (
    <div>
        <Testimonialbanner/>
        <Homereview/>
        {/* <Testimonialreview/> */}
    </div>
  )
}

export default Testimonials