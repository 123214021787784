import React, { useEffect } from 'react'
import "./terms&conditions.css"
import { useTranslation } from 'react-i18next';

const TermsAndconditions = () => {
  const{t} = useTranslation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' 
    });
  }, []);
  return (
    <div>
      <div className='TC_main_div'>
        <div className='TC_title_div'> 
          <h2>{t("LEGAL DISCLAIMER")}</h2>
        </div>
        <div className='TC_content_div'>
        <p>{t("This document regulates the terms of use of the usaintern.club website (hereinafter: Website), the rights and obligations of the visitor of the Website, or the person using the services of the Website (hereinafter: User), and the operator of the Website (Operator).")}
        </p>
        <br />
          <div className='TC_list'>
            <React.Fragment>
              <li >
                <p className='TC_list_p1'>1.</p>
                <p className='TC_list_p2'>{t("This document is prepared in the English language.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>2.</p>
                <p className='TC_list_p2'>{t("By starting to use the Website, the User acknowledges and accepts the provisions of the Legal Disclaimer.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>3.</p>
                <p className='TC_list_p2'>{t("The services of the Website - unless otherwise expressly stated on the Website - are available to Users free of charge.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>4.</p>
                <p className='TC_list_p2'>{t("Regarding the content possibly published by Users or other third parties on the Website, the Operator qualifies as an employment agency under Act CVIII of 2001 on certain issues of electronic commerce services and information society services.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>5.</p>
                <p className='TC_list_p2'>{t("The information available on the Website - except for information directly related to the activities of the Operator - is solely for informational purposes, and the Operator is not responsible for the accuracy of the information contained therein. Furthermore, the Operator does not provide any tax or legal advice to the User, and any such information is published on the Website solely for informational purposes, with the Operator not being responsible for its accuracy.")}
                </p>
                </li>
              <li>
                <p className='TC_list_p1'>6.</p>
                <p className='TC_list_p2'>{t("In matters not regulated by this Legal Disclaimer, Hungarian laws in force shall apply. Any legal disputes arising from the use of the Website shall fall within the jurisdiction of Hungarian courts, and Hungarian law shall apply during the proceedings. In matters within the jurisdiction of the court, the Székesfehérvár Tribunal shall be competent, and in matters within the jurisdiction of the district court, the Budapest Central District Court shall be competent.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>7.</p>
                <p className='TC_list_p2'>{t("The Website constitutes the intellectual property of the Operator, and any use of the logo found therein, as well as all graphic and structural elements, in any form - especially their use in commercial transactions or other commercial activities - shall only be carried out with the prior written permission of the Operator.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>8.</p>
                <p className='TC_list_p2'>{t("The Operator is also entitled to protection as the creator of all information and data (Database) found on the Website under the provisions of Act LXXVI of 1999 on copyright protection. The disclosure of the Database or any part thereof, or the making of copies thereof, requires the prior consent of the Operator in all cases.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>9.</p>
                <p className='TC_list_p2'>{t("Pursuant to Section 29(1)(a) of Government Decree 45/2014 (II.26.) on the detailed rules of contracts between consumers and businesses (hereinafter: Government Decree) - if the User qualifies as a consumer in the case of the service in question, and the scope of the Government Decree extends to the relevant legal relationship - the User expressly agrees that the Operator shall immediately commence the provision of the entire service. By using the services of the Website, the entire service shall be considered fulfilled. The User acknowledges that after the performance of the entire service referred to above, they forfeit their right to terminate.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>10.</p>
                <p className='TC_list_p2'>{t("The User declares and warrants that they possess the necessary understanding to use the services of the Website. The Operator expressly excludes its liability for any damage resulting from the lack of such understanding.")}</p>
              </li>
              <li>
                <p className='TC_list_p1'>11.</p>
                <p className='TC_list_p2'>{t("The Operator accepts complaints related to the operation of the Website and the services provided through the Website at the contact details provided in the 'Contact Us' section of the Website. Complaints shall be investigated within the deadlines specified by law. Information regarding the investigated complaints shall be sent to the User electronically by the Operator.")}
                </p>
              </li>
              <li>
                <p className='TC_list_p1'>12.</p>
                <p className='TC_list_p2'>{t("The Operator is not subject to a code of conduct.")}</p>
                </li>
              <li>
                <p className='TC_list_p1'>13.</p>
                <p className='TC_list_p2'>{t("The rules for the processing of personal data are contained in the Privacy Policy available on the Website.")}
                </p>
              </li>
            </React.Fragment>
          </div>
          <div className='TC_content2_div'>
            <p className='TC_contactus_title'>{t("Contacts for supervisory and other authorities and organizations:")}</p>
            <div className='TC_contactus'>
              <p><b>{t("Hungarian Competition Authority:")}</b></p>   <p>1026 Budapest, Riadó u. 1-3.</p>
              <p>{t("Mailing address: 1534 Budapest Pf.: 958")}</p>
              <p>{t("Telephone: +36 (1) 472-8900")}</p>
              <br />
            </div>
            <div className='TC_contactus'>
              <p><b>{t("Office of the National Media and Infocommunications Authority:")}</b></p>
              <p>1015 Budapest, Ostrom u. 23-25.</p>
              <p>{t("Mailing address: 1525. Pf. 75")}</p>
              <p>Tel: (06 1) 457 7100</p>
              <p>Fax: (06 1) 356 5520</p>
              <p>Email:<a href="mailto:info@nmhh.hu" className='TC_link'>info@nmhh.hu</a></p>
            </div>
            <br />
            <div className='TC_contactus'>
              <p><b>{t("Territorial supervisory authorities of the National Consumer Protection Authority")}</b></p>
              <a  href="https://fogyasztovedelem.kormany.hu/#/hova_fordulhatok" className='TC_link' target='_blank'>https://fogyasztovedelem.kormany.hu/#/hova_fordulhatok</a>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndconditions