// logos
import navlogo from "../assets/logos/navlogo.png";
import mail from "../assets/logos/mail.png";
import howcanhelplogo1 from "../assets/logos/howcanhelplogo1.png";
import howcanhelplogo2 from "../assets/logos/howcanhelplogo2.png";
import footer_logo_1 from "../assets/logos/footer_logo_1.webp";
import footer_logo_2 from "../assets/logos/footer_logo_2.webp";
import blog_card_arrow from "../assets/logos/blog_card_arrow.png";
import blog_card_shap from "../assets/logos/blog_card_shap.png";
import about_whatwe_logo from "../assets/logos/about_whatwe_logo.png";
import about_whatwe_logo_hu from "../assets/logos/about_whatwe_logo_hu.png";
import us_flag from "../assets/logos/us-flag (1).png";
import hug_flag from "../assets/logos/hu-flag.jpg";

// images
import herowave from "../assets/images/herowave.png";
import herowave_2 from "../assets/images/herowave_2.png";

// --- about
import about_mession_back from "../assets/images/about/about_mession_back.webp";
import about_whatwe from "../assets/images/about/about_whatwe.png";
import about_whatwe2 from "../assets/images/about/about_whatwe2.webp";
// import about_banner from "../assets/images/about/about_banner.png";
// import about_banner from "../assets/images/about/about_banner.jpg";
import about_whychoose from "../assets/images/about/about_whychoose_en.webp";
import howcanhelpimg1 from "../assets/images/about/howcanhelpimg1.webp";
import howcanhelpimg2 from "../assets/images/about/howcanhelpimg2.webp";
import about_banner from "../assets/images/about/Aboutus page banner.webp";

// --- blog
// import blogheroimg from "../assets/images/blog/blogheroimg.png";
import blogheroimg from "../assets/images/blog/blog_page-banner.jpg";
import blog_detail_img from "../assets/images/blog/blog_detail_img.webp";
import blog_detail_img2 from "../assets/images/blog/blog_detail_img2.png";
import blog_detail_img1_mobile from "../assets/images/blog/blog_detail_img1_mobile.webp";
import blog_detail_avtar from "../assets/images/blog/blog_detail_avtar.png";
import recent_blog_img1 from "../assets/images/blog/recent_blog_img1.png";
import recent_blog_img2 from "../assets/images/blog/recent_blog_img2.png";
import recent_blog_img3 from "../assets/images/blog/recent_blog_img3.png";

// import recent_blog_img3 from "../assets/images/blog/recent_blog_img3.png";
// import recent_blog_img3 from "../assets/images/blog/recent_blog_img3.png";

import slider_left_arrow from "../assets/images/blog/Group 40106.png";
import slider_right_arrow from "../assets/images/blog/Group 40105.png";

import bd_banner_au from "../assets/images/blog/blog_banner_au.webp";
import bd_banner_au_mob from "../assets/images/blog/blog_banner_au_mob.webp";

// ---home
import HomePagebanner_1 from "../assets/images/home/Home_page_banner-1.webp";
import HomePagebanner_2 from "../assets/images/home/Home_page_banner-2.webp";
import HomePagebanner_3 from "../assets/images/home/Home_page_banner-3.webp";
import HomePagebanner_4 from "../assets/images/home/Home_page_banner-4.webp";

import english from "../assets/images/home/us.png";
import hungaria from "../assets/images/home/hu-flag.jpg";
import usa_internlogo from "../assets/images/home/Usaintern_Logo.png";
import mouse_btn from "../assets/images/home/Button.png";
import banner_bottom from "../assets/images/home/banner_home_img.png";
import sec2_card_img1 from "../assets/images/home/cardimg-1.webp";
import sec2_card_img2 from "../assets/images/home/professional-training.webp";
import sec2_card_img3 from "../assets/images/home/cardimg-3.webp";
import sec2_card_img5 from "../assets/images/home/cardimg-au.webp";
import sec2_bg from "../assets/images/home/people-learning.webp";
import sec2_card_img4 from "../assets/images/home/step-usa.webp";
import hr_img from "../assets/images/home/slider-img1.webp";
import graphic_img from "../assets/images/home/slider-img2.webp";
import business_img from "../assets/images/home/busine.webp";
import marketting_img from "../assets/images/home/slider-img4.webp";
import agriculture_img from "../assets/images/home/slider-img5.webp";
import finance_img from "../assets/images/home/slider-img6.webp";
import sales_img from "../assets/images/home/sales.webp";
import it_img from "../assets/images/home/IT.webp";
import culninary_img from "../assets/images/home/culinary.webp";
import hospitality_front_img from "../assets/images/home/hospitality-front_480.webp";
import hospitality_front_480 from "../assets/images/home/hospitality-front_480.webp";
import hospitality_fb_img from "../assets/images/home/hospitality-f&b.webp";
import hospitality_img from "../assets/images/home/hospitality.webp";
import mechanical_img from "../assets/images/home/mechanical.webp";
import architecture_img from "../assets/images/home/architecture.webp";
import arrow_left from "../assets/images/home/arrow-left.png";
import arrow_right from "../assets/images/home/arrow-right.png";

import card1_wave from "../assets/images/home/Vector 1.webp";
import card2_wave from "../assets/images/home/Vector 1 (1).webp";
import card3_wave from "../assets/images/home/Vector 1 (2).webp";
import card4_wave from "../assets/images/home/Vector 1 (5).webp";
import card5_wave from "../assets/images/home/Vector 1 (4).webp";

import h_a_email from "../assets/images/home/div.flex-shrink-0 (2).png";
import h_a_skype from "../assets/images/home/Group 40083 (1).webp";
import h_a_img from "../assets/images/home/h-a-img.webp";
import tick from "../assets/images/home/Symbol.png";

import check from "../assets/images/home/check.png";
import Spinnerr from "../assets/images/home/spinner.gif";

// home-gallery
import gallery_img from "../assets/images/home/gallery-img.webp";

// student
import student_bg from "../assets/images/home/Group 155.webp";

// section6
import student_img1 from "../assets/images/home/Rectangle 201.webp";
import student_img2 from "../assets/images/home/Rectangle 203.webp";
import student_img3 from "../assets/images/home/Rectangle 207.webp";
import student_img4 from "../assets/images/home/review2.webp";
import student_img5 from "../assets/images/home/review3.webp";

// home-blog
import blog_img1 from "../assets/images/home/Rectangle 115.webp";
import blog_img1_banner from "../assets/images/home/blog_img1_banner.webp";
import blog_img1_mob from "../assets/images/home/blog_img1_mob.webp";
// import blog_img2 from "../assets/images/home/Rectangle 113.png";
import blog_img2 from "../assets/images/home/j1_visa_blog.webp";
import blog_img2_banner from "../assets/images/home/blog_img2_banner.webp";
import blog_img2_mob from "../assets/images/home/blog_img2_mob.png";
// import blog_img3 from "../assets/images/home/40e0c7e43a6cd768f4aaf58daadde44f .png";
import blog_img3 from "../assets/images/home/interview_blog.webp";
import blog_img3_mob from "../assets/images/home/blog_img3_mob.webp";
import blog_img3_banner from "../assets/images/home/blog_img3_banner.webp";
import blog_img4 from "../assets/images/blog/au-pair_blog.png";

import question_img from "../assets/images/home/Group (1) (1).webp";
import question_red_btn from "../assets/images/home/orange_hide.webp";
import question_blue_btn from "../assets/images/home/blue_show.webp";

import getintouch_bg from "../assets/images/home/BG (1).png";
import getin_email from "../assets/images/home/message-notif (2).png";
import getin_skype from "../assets/images/home//skype-svgrepo-com 1 (1) (1).png";
import getin_img from "../assets/images/home/Signup.png";

// contactUs
import contactus_banner from "../assets/images/contact/Contactus-Banner-_1_.webp";

import banner_bottom1 from "../assets/images/contact/banner-btm.png";
import sec2_img from "../assets/images/contact/Rectangle 730.webp";
import msg_notif from "../assets/images/contact/message-notif (1).png";
import calling from "../assets/images/contact/call-calling.png";
import skype from "../assets/images/contact/skype-svgrepo-com 1 (1).png";
import fb from "../assets/images/contact/Facebook - Original (1).png";
import skype_1 from "../assets/images/contact/Skype - Original.png";
import insta from "../assets/images/contact/Instagram - Original.png";
import linkedin from "../assets/images/contact/LinkedIn - Original.png";
import tiktok from "../assets/images/contact/TikTok - Original.png";
import fb_hover from "../assets/images/contact/Group 40084.png";
import skype_hover from "../assets/images/contact/skype-hover.png";
import insta_hover from "../assets/images/contact/Group 40085.png";
import git_hover from "../assets/images/contact/Group 40086.png";
import tiktok_hover from "../assets/images/contact/tiktok-hover.png";
import linkedin_hover from "../assets/images/contact/linkedin-hover.png";

//testimonials
import student1 from "../assets/images/testimonial/Rectangle 203.jpeg";
import left_quote from "../assets/images/testimonial/left-quote 1 (Traced) (1).png";
import review2 from "../assets/images/testimonial/review2.jpeg";
import review3 from "../assets/images/testimonial/review3.jpeg";

//programs
import program_banner1 from "../assets/images/programs/pro-banner1.png";
import program_banner2 from "../assets/images/programs/program-banner2.png";
import Au_Pair_banner from "../assets/images/programs/Au_Pair_banner.png";
import list_icon from "../assets/images/programs/list-icon.png";
import green_tick from "../assets/images/programs/green-tick.png";

// tax
import taxbanner from "../assets/images/tax/taxbanner.png";
import tax_img2 from "../assets/images/tax/tax_img2.png";
import taxbanner_blog from "../assets/images/tax/taxbanner_blog.webp";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // logos
  navlogo,
  mail,
  howcanhelplogo1,
  howcanhelplogo2,
  footer_logo_1,
  footer_logo_2,
  blog_card_arrow,
  blog_card_shap,
  about_whatwe_logo,
  about_whatwe_logo_hu,
  us_flag,
  hug_flag,

  // images
  herowave,
  herowave_2,

  // -- about
  about_banner,
  about_mession_back,
  about_whatwe,
  about_whatwe2,
  about_whychoose,
  howcanhelpimg1,
  howcanhelpimg2,

  // -- blog
  blogheroimg,
  blog_detail_img,
  blog_detail_img2,
  blog_detail_avtar,
  recent_blog_img1,
  recent_blog_img2,
  recent_blog_img3,
  blog_detail_img1_mobile,

  slider_left_arrow,
  slider_right_arrow,

  bd_banner_au,
  bd_banner_au_mob,

  blog_img1_mob,
  blog_img1_banner,
  blog_img3_mob,
  blog_img3_banner,
  blog_img2_banner,
  blog_img2_mob,

  // -- home
  HomePagebanner_1,
  HomePagebanner_2,
  HomePagebanner_3,
  HomePagebanner_4,

  english,
  hungaria,
  usa_internlogo,
  mouse_btn,
  banner_bottom,
  sec2_card_img1,
  sec2_card_img2,
  sec2_card_img3,
  sec2_card_img4,
  sec2_card_img5,
  sec2_bg,
  check,
  Spinnerr,

  // slider img
  hr_img,
  graphic_img,
  business_img,
  marketting_img,
  agriculture_img,
  finance_img,
  sales_img,
  it_img,
  culninary_img,
  hospitality_fb_img,
  hospitality_front_img,
  hospitality_front_480,
  hospitality_img,
  mechanical_img,
  architecture_img,

  arrow_left,
  arrow_right,

  // home-about
  h_a_email,
  h_a_skype,
  h_a_img,
  tick,

  // home-gallery
  gallery_img,

  student_bg,

  student_img1,
  student_img2,
  student_img3,
  student_img4,
  student_img5,

  card2_wave,
  card1_wave,
  card3_wave,
  card4_wave,
  card5_wave,

  blog_img1,
  blog_img2,
  blog_img3,
  blog_img4,

  question_img,
  question_blue_btn,
  question_red_btn,

  getintouch_bg,
  getin_email,
  getin_skype,
  getin_img,

  //contact us
  contactus_banner,
  banner_bottom1,
  sec2_img,
  msg_notif,
  calling,
  skype,

  fb,
  insta,
  skype_1,
  linkedin,
  tiktok,
  fb_hover,
  insta_hover,
  skype_hover,
  git_hover,
  linkedin_hover,
  tiktok_hover,

  //testimonial
  student1,
  left_quote,
  review2,
  review3,

  //program
  program_banner2,
  list_icon,
  program_banner1,
  green_tick,
  Au_Pair_banner,

  // tax
  taxbanner,
  tax_img2,
  taxbanner_blog,
};
