import React from "react";
import "./AboutWhyChoose.css";
import images from "../../constants/images";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";

const AboutWhyChoose = () => {
  const{t,i18n} = useTranslation();
  const imageSrc = i18n.language === "en"
    ? require("../../assets/images/about/about_whychoose_en.webp")
    : require("../../assets/images/about/about_whychoose_hu.webp");
  return (
    <div className="main_wrapp">
      <div className="container aboutweare_main_wrapp">
        <div className="aboutwhychoose_inner_wrapp">
          {/* image */}
          <div className="aboutwhychoose_img_wrapp">
            <img
              src={imageSrc}
              className="about_whatwe_img"
              alt="about_whychoos"
              loading="lazy" decoding='async'
            />
          </div>

          {/* text */}
          <div className="aboutwhychoos_textwrapp">
            <p className="aboutwhatwe_orange_text">{t("WHY CHOOSE USAINTERN CLUB?")}</p>
            <p className="aboutwhatwe_blue_text">
              {t("Providing transformative internship experience")}
            </p>
            <p className="about_whychoose_des_text">
              {t("Embark on a journey of professional growth with our transformative internship experience. We're dedicated to providing you with hands-on opportunities that not only enhance your skills but also open doors to new possibilities.")}
            </p>
            <div className="about_whychoose_boxes_wrapp">
              <div>
                <IoIosCheckmarkCircleOutline color="#FF5E14" size={25} />
                <p>{t("Expert Guidance")}</p>
              </div>
            </div>
            <div className="about_whychoose_boxes_wrapp">
              <div style={{ borderColor: "#02245B" }}>
                <IoIosCheckmarkCircleOutline color="#02245B" size={25} />
                <p>{t("Transparent Processes")}</p>
              </div>
            </div>
            <div className="about_whychoose_boxes_wrapp">
              <div style={{ borderColor: "#1EC199" }}>
                <IoIosCheckmarkCircleOutline color="#1EC199" size={25} />
                <p>{t("Preparation and Readiness")}</p>
              </div>
            </div>
            <div className="about_whychoose_boxes_wrapp">
              <div style={{ borderColor: "#ED4883" }}>
                <IoIosCheckmarkCircleOutline color="#ED4883" size={25} />
                <p>{t("Success Stories")}</p>
              </div>
            </div>
            <div className="about_whychoose_boxes_wrapp">
              <div style={{ borderColor: "#037164" }}>
                <IoIosCheckmarkCircleOutline color="#037164" size={25} />
                <p>{t("Cultural Integration")}</p>
              </div>
            </div>
            <div className="about_whychoose_boxes_wrapp">
              <div style={{ borderColor: "#701A75" }}>
                <IoIosCheckmarkCircleOutline color="#701A75" size={25} />
                <p>{t("Continuous Support")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutWhyChoose;
