import React, { useEffect } from 'react'
import images from '../../constants/images'
import "./taxreturnbanner.css"

const TaxreturnBanner = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);
  return (
    <div>
        <div className="tax_main_wrapp2">
           {/* <img src={images.taxbanner} alt="" /> */}
            <div className='tax-title'>
                <p>U.S. Tax Return</p>
            </div>
        </div>
     </div>
  )
}

export default TaxreturnBanner