import React from "react";
import "./Footer.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaSkype, FaTiktok } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const{t} = useTranslation();
  return (
    <div className="main_wrapp footer_main_wrapp">
      <div className="container">
        <div className="footer_first_wrapp">
          <div className="footer_first_wrapp_sec1">
            <img src={images.navlogo} alt="" />
            {/* <img src={images.navlogo} alt="" /> */}
          </div>
          {/* <div className="footer_first_wrapp_sec2">
            <Link to={"/"}>Home</Link>
            <Link to={"/about"}>About Us</Link>
            <Link to={"/programs"}>Programs</Link>
            <Link to={"/Testimonials"}>Testimonials</Link>
            <Link to={"/Blog"}>Blog</Link>
            <Link to={"/TaxReturn"}>Tax Return</Link>
            <Link to={"/contactus"}>FAQ</Link>
          </div> */}
          <div className="footer_first_wrapp_sec3">
            <a href="https://www.facebook.com/usaintern.club" target="_blank">
              <FaFacebook size={16} color="#090914" />
              <p>Facebook</p>
            </a>
            <a href="https://www.instagram.com/usaintern.club/" target="_blank">
              <FaInstagram size={16} color="#090914" />
              <p>Instagram</p>
            </a>
            <a href="https://www.tiktok.com/@usaintern.club?_t=8jeZfuyhRVK&_r=1" target="_blank">
              <FaTiktok size={16} color="#090914" />
              <p>Tiktok</p>
            </a>
          </div>

          {/* <div className="footer_first_wrapp_sec4"> */}

            {/* <div>
              <div className="msicon_div"><FiMail size={16} color="#090914" /></div>
              <div className="footer_first_wrapp_sec4_part2">
                <label>Talk to our experts</label>
                <a href="mailto:info@usaintern.club">
                  <p>mail : info@usaintern.club</p>
                </a>
              </div>
            </div>
            <div>
              <div className="msicon_div"><FaSkype size={16} color="#090914" /></div>
              <div className="footer_first_wrapp_sec4_part2">
                <label>Skype</label>
                <a href="skype:usaintern.club?chat">
                  <p>skype : usaintern.club</p>
                </a>
              </div>
            </div> */}
          {/* </div> */}
          <div className="footer_first_wrapp_sec5">
            <img src={images.footer_logo_1} alt="" />
            <img src={images.footer_logo_2} alt="" />
            {/* <img src={images.navlogo} alt="" /> */}
          </div>
        </div>
        <div className="footer_secound_wrapp"></div>
        <div className="footer_third_wrapp">
          <div>
            <p>&#xA9;Usa Intern Club / {t("All Right Reserved")}</p>
          </div>
          <div>
            <p className="terms_privacy"> <Link to={"/Terms&conditions"}> {t("Terms and Conditions")}</Link> <span style={{color:"black"}}>|</span> <Link to={"/PrivacyPolicy"}>{t("Privacy Policy")}</Link> </p>
          </div>
          <div>
            <p className="design-by"> 
            <a href="https://binarygeckos.com/" target="_blank">Developed By Binary Geckos</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
