import React from "react";
import "./contactsection1.css";
import images from "../../constants/images";
import { RiMessengerLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const Contactsection1 = () => {
  const{t} = useTranslation();
  const openSkypeChat = () => {
    window.location.href = 'skype:usaintern.club?chat';
  };
  const openEmailClient = () => {
    window.location.href = 'mailto:info@usaintern.club';
  };
  
  return (
    <div className="contact-section2">
      <div className="contact-main-wrap">
        <div className="contact-img-div">
          <img src={images.sec2_img} alt="contact-img" className="contact-img" />
          <div className="contact-ways">
            <div className="contact-main">
              <p className="con-sec2-title">{t("contact")}</p>
              <p className="con-sec2-des1">{t("We would love to hear from you")}</p>
              <br />
              <p className="con-sec2-des2">
                {t("Contact us for personalized guidance and assistance on your journey to unlocking exciting internship and training opportunities with USA Intern Club.")}
              </p>
            </div>
            <div className="con-section2-2">
              <div className="contact-sec2-email">
                <div className="circle" onClick={openEmailClient}>
                  {/* <a href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer"
                      className="icon_a"> */}
                    <img src={images.msg_notif} alt="msg_notification" />
                  {/* </a> */}
                </div>
                <div className="main-content" onClick={openEmailClient}>
                  {/* <a href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer"> */}
                  <p className="email">{t("Email Address")}</p>
                  <p className="content">
                    <a href="mailto:info@usaintern.club" target="_blank"
                      rel="noreferrer">info@usaintern.club</a>
                  </p>
                  {/* </a> */}
                </div>
              </div>
              {/* <div className="contact-sec2-mob">
                            <div className='circle'>
                                <img src={images.calling} alt="" />
                            </div>
                            <div className='main-content' >
                                <p className='email'>Phone Number</p>
                                <p className='content'><a href="tel:+36 70 575 9566">+36 70 575 9566</a></p>
                            </div>
                        </div> */}
              <div className="contact-sec2-mob">
                <div className="circle">
                  <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://m.me/676609959134657"
                      className="icon_a"
                    >
                    <RiMessengerLine size={16} />
                  </a>
                </div>
                <div className="main-content">
                  <p className="email">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://m.me/676609959134657"
                    >
                      {t("Facebook Messenger")}
                    </a>
                  </p>
                </div>
              </div>
              <div className="contact-sec2-skype"  >
                <div className="circle" onClick={openSkypeChat}>
                  {/* <a href="skype:usaintern.club?chat" target="_blank"
                       className="icon_a"> */}
                    <img src={images.skype} alt="skype" />
                  {/* </a> */}
                </div>
                <div className="main-content" onClick={openSkypeChat}>
                  <a href="skype:usaintern.club?chat" target="_blank"
                      >
                  <p className="email">{t("Skype")}</p>
                  <p className="content">
                    <a href="skype:usaintern.club?chat" target="_blank"
                      >usaintern.club</a>
                  </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactsection1;
