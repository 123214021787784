import React, { useState, useEffect } from "react";
import "./homebanner.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../../GlobalStateContext";

const Homebanner = () => {
  const { setScrollPosition } = useGlobalState();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageArray = [
    images.HomePagebanner_1,
    images.HomePagebanner_2,
    images.HomePagebanner_3,
    images.HomePagebanner_4,
  ];
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000, // Change the duration as needed
    fade: true,
    speed: 1000, // Change the speed as needed
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // Function to update scroll position to 1 (bottom) when Apply Now is clicked
  // const handleApplyNowClick = () => {
  //   setScrollPosition(1);
  // };

  // useEffect(() => {
  //   const observer = new PerformanceObserver((list) => {
  //     const latestEntry = list.getEntries().at(-1);

  //     if (latestEntry?.element?.getAttribute('loading') === 'lazy') {
  //       console.warn('Warning: LCP element was lazy loaded', latestEntry);
  //     }
  //   });

  //   observer.observe({ type: 'largest-contentful-paint', buffered: true });

  //   return () => observer.disconnect();
  // }, []);
  return (
    <div className="home-section1">
      <Slider {...settings} className="home_banner_slider">
        {imageArray.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`banner-${index}`}
              className="h_banner_img"
              loading="lazy"
              decoding="async"
            />
          </div>
        ))}
      </Slider>
      <div className="home-sec1-div">
        <div className="home-sec1-content-div">
          <p>
            {t("Embark on Your Dream")}
            <br />
            {t("Internship Journey in the USA")}
          </p>
          {/* <p>{t('Embark on Your Dream Internship Journey in the USA')}</p> */}
          <Link to={"https://app.usaintern.club/server/pathtosuccess/redirect?url=/../../app/signup.html"}
          // state={{ scroll: "true" }}
          // onClick={handleApplyNowClick} 
          className="apply-btn"
          target="_blank">
            
              {t("apply now")}
            {/* </Link> */}
          </Link>
          {/* <button className='apply-btn'><Link to="/contactus" state={{ scroll: 'true' }}>{t('apply now')}</Link></button> */}
        </div>
      </div>
      <div>
        <img
          src={images.banner_bottom}
          alt="home-wave"
          className="blue-banner-img"
          // loading="lazy"
          // decoding="async"
        />
      </div>
    </div>
  );
};

export default Homebanner;
